import { createSelector, createSlice } from '@reduxjs/toolkit';

import { LikesState, SetLikesAction } from './interface';
import { RootState } from 'store/rootReducer';

const initialState: LikesState = {
  likes: [],
  loadingStatus: 'loading',
};

export const { actions, reducer } = createSlice({
  name: 'likes',
  initialState,
  reducers: {
    loadLikes(state) {
      state.loadingStatus = 'loading';
    },
    setLikes(state, action: SetLikesAction) {
      state.loadingStatus = 'loaded';
      state.likes = action.payload;
    },
    setLikesFailed(state) {
      state.loadingStatus = 'failed';
      state.likes = [];
    },
  },
});

export { reducer as likesReducer, actions as likesActions };

export const likesSelector = (state: RootState) => state.likes;
