import s from './PhotosEdit.module.sass';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { FC, useCallback, useEffect, useState } from 'react';
import { PhotosEditProps } from '$shared/components/PhotosEdit/PhotosEdit.interface';
import { toastActions } from '$store/toast';
import { canvasToBlob } from '$utils';
import { Api } from '$api';
import { useDispatch } from 'react-redux';
import * as loadImage from 'blueimp-load-image';
import { Icon12Cancel, Icon28AddOutline } from '@vkontakte/icons';
import { HorizontalScroll } from '@vkontakte/vkui';
import { PhotoModel } from '$store/models';
import { arrayMoveImmutable } from 'array-move';

const SortableItem = SortableElement(
  ({ onDelete, photo }: { photo: PhotoModel; onDelete: any }) => {
    return (
      <div
        className={s.photo}
        key={photo.id}
        style={{ backgroundImage: `url(${photo.url})` }}
      >
        <div className={s.photoCloseWrap} onClick={onDelete}>
          <div className={s.photoClose}>
            <Icon12Cancel />
          </div>
        </div>
      </div>
    );
  },
);

const SortableList = SortableContainer(
  ({ photos, handleDeletePhoto }: { photos: PhotoModel[]; handleDeletePhoto: any }) => {
    return (
      <div className={s.photosAdded}>
        {photos.map((photo, i) => (
          // @ts-ignore
          <SortableItem
            key={`item-${photo.id}`}
            photo={photo}
            index={i}
            onDelete={handleDeletePhoto(i)}
          />
        ))}
      </div>
    );
  },
);

export const PhotosEdit: FC<PhotosEditProps> = (props) => {
  const { photos, setPhotos, prefetchPhoto } = props;

  const dispatch = useDispatch();

  const [photoKey, setPhotoKey] = useState(0);

  const handlePhoto = useCallback(({ target }) => {
    setPhotoKey(Date.now());
    if (target.files.length === 0) {
      return;
    }

    try {
      window.focus();
    } catch (e) {}

    dispatch(toastActions.setToastLoading());
    loadImage(target.files[0], {
      maxWidth: 1920,
      maxHeight: 1920,
      meta: true,
      canvas: true,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
      orientation: true,
    })
      .then((data) => {
        const quality = 0.8;
        canvasToBlob(data.image, quality)
          .then((blob) => {
            const form = new FormData();
            form.append('file', blob);
            Api.post('/photos/upload', form)
              .then((res) => {
                const d: any = res;
                setPhotos((prev) => [d.photo, ...prev]);
                dispatch(toastActions.hideToast());
              })
              .catch((err) => {
                dispatch(toastActions.setToastFail(err.message));
              });
          })
          .catch((err) => {
            dispatch(toastActions.setToastFail(err.message));
          });
      })
      .catch((err) => {
        dispatch(toastActions.setToastFail(err.message));
      });
  }, []);

  useEffect(() => {
    if (prefetchPhoto) {
      dispatch(toastActions.setToastLoading());
      fetch(prefetchPhoto)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], 'photo.jpg', { type: 'image/jpeg' });
          handlePhoto({
            target: {
              files: [file],
            },
          });
        })
        .catch(() => {
          dispatch(toastActions.hideToast());
        });
    }
  }, [prefetchPhoto]);

  const handleDeletePhoto = useCallback(
    (index: number) => () => {
      setPhotos((prev) => {
        const old = [...prev];
        const prefix = index > 0 ? old.slice(0, index) : [];
        return [...prefix, ...old.slice(index + 1)];
      });
    },
    [],
  );

  return (
    <HorizontalScroll>
      <div className={s.photos}>
        {photos.length < 10 && (
          <div className={s.photo}>
            <Icon28AddOutline />
            <input type="file" accept="image/*" onChange={handlePhoto} key={photoKey} />
          </div>
        )}
        {/* @ts-ignore */}
        <SortableList
          axis="x"
          lockAxis="x"
          photos={photos}
          handleDeletePhoto={handleDeletePhoto}
          onSortEnd={({ oldIndex, newIndex }) => {
            setPhotos((prev) => arrayMoveImmutable(prev, oldIndex, newIndex));
          }}
          pressDelay={100}
        />
      </div>
    </HorizontalScroll>
  );
};
