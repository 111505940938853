import s from './HorizontalScroll.module.sass';

import { FC } from 'react';
import { HorizontalScrollProps } from '$uikit/HorizontalScroll/HorizontalScroll.interface';

export const HorizontalScroll: FC<HorizontalScrollProps> = ({ children, style }) => {
  return (
    <div className={s.wrap} style={style}>
      <div className={s.scroll}>{children}</div>
    </div>
  );
};
