import { createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from '$store/rootReducer';
import {
  CardsState,
  CardsSwipeAction,
  SetAdAction,
  SetCardsAction,
  SetLoadingStatusAction,
} from '$store/cards/interface';

const initialState: CardsState = {
  cards: [],
  loadingStatus: 'loading',
  disliked: [],
  ads: {},
};

let seen: any = {};

export const { actions, reducer } = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    clear(state) {
      state.cards = [];
      seen = {};
    },
    loadCards(state) {
      state.loadingStatus = 'loading';
    },
    setLoadingStatus(state, action: SetLoadingStatusAction) {
      state.loadingStatus = action.payload;
    },
    setCards(state, action: SetCardsAction) {
      const copy = [...state.cards];
      const exist: any = {};
      for (let item of copy) {
        exist[item] = true;
      }
      for (let item of action.payload) {
        if (!exist[item] && !seen[item]) {
          copy.push(item);
          seen[item] = true;
        }
      }
      state.cards = copy;
    },
    swipe(state, action: CardsSwipeAction) {
      state.cards = [...state.cards].filter((card) => card !== action.payload.userId);
      if (action.payload.reason === 1 && action.payload.userId > 0) {
        state.disliked.push(action.payload.userId);
      }
    },
    cancelAction(state) {
      const lastDisliked = state.disliked.pop();
      if (lastDisliked) {
        state.cards.unshift(lastDisliked);
      }
    },
    setAd(state, action: SetAdAction) {
      state.ads[action.payload.id] = action.payload;
    },
  },
});

export { reducer as cardsReducer, actions as cardsActions };

export const cardsSelector = (state: RootState) => state.cards;

export const dislikedCardsSelector = (state: RootState) => state.cards.disliked;
