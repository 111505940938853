import { FC, useCallback } from 'react';
import { Group, SimpleCell } from '@vkontakte/vkui';
import copy from 'clipboard-copy';
import { getToken } from '$utils';

export const Debug: FC = () => {
  const handleToken = useCallback(() => {
    copy(getToken());
  }, []);

  return (
    <>
      <Group>
        <SimpleCell onClick={handleToken}>Copy token</SimpleCell>
      </Group>
    </>
  );
};
