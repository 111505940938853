import './Linkify.sass';

import React, { useMemo, ReactNode, FC } from 'react';
import linkifyIt from 'linkify-it';

import { classNames as cn } from '$utils';
import { store } from '$store/store';
import { LinkifyProps } from '$shared/components/Linkify/Linkify.interface';
import WebApp from '@twa-dev/sdk';

const inst = linkifyIt().tlds('place', true);

inst.add('@', {
  validate: /^(id|group)\d+|^[a-zA-Z_\d]{4,15}/,
  normalize(match) {
    match.schema = 'mention';
    match.url = match.text.replace(/^@/, '');
  },
});

export function findLink(children: ReactNode, opts: any = {}): ReactNode {
  return React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        // @ts-ignore
        children: findLink(child.props.children),
      });
    }

    if (!child || typeof child !== 'string') {
      return null;
    }

    const handleClick = (e) => e.stopPropagation();
    const matches = inst.match(child);
    if (!matches) {
      return child;
    }

    let result: ReactNode[] = [];
    let lastIndex = 0;
    let i = 0;
    for (let match of matches) {
      result.push(child.substring(lastIndex, match.index));

      if (match.schema === 'mention') {
        result.push(
          <a onClick={() => WebApp.openTelegramLink(`https://t.me/${match.url}`)}>
            {match.text}
          </a>,
        );
      } else {
        if (match.url.indexOf('t.me') > -1 || match.url.indexOf('vk.com') > -1) {
          result.push(<a onClick={() => WebApp.openLink(match.url)}>{match.text}</a>);
        } else {
          result.push('[Ссылки запрещены]');
        }
      }
      lastIndex = match.lastIndex;
    }
    result.push(child.substring(lastIndex));

    return result;
  });
}

export const Linkify: FC<LinkifyProps> = ({ children, disabled = false, className }) => {
  const prepared = useMemo(() => {
    return disabled ? children : findLink(children);
  }, [disabled, children]);

  return <div className={cn('Linkify', className)}>{prepared}</div>;
};
