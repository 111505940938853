import { FC, useCallback, useLayoutEffect, useState } from 'react';
import { CellButton, InlineHeader, ScrollView } from '$uikit';
import { Api } from '$api';
import { useDispatch } from 'react-redux';
import { toastActions } from '$store/toast';
import { UserModel } from '$store/models';
import {
  Button,
  Cell,
  Group,
  CellButton as VKCellButton,
  List,
  Placeholder,
  Switch,
} from '@vkontakte/vkui';
import { Icon56ErrorOutline } from '@vkontakte/icons';
import { useTranslator } from '$hooks';
import {
  classNames,
  getLookingForLabel,
  getLookingForSmile,
  transformUserDistance,
} from '$utils';
import { IcLocation28, IcStar28 } from '$assets';
import s from './ModerVerify.module.sass';

export const ModerVerify: FC = () => {
  const t = useTranslator();
  const dispatch = useDispatch();
  const [loadingStatus, setLoadingStatus] = useState('loading');
  const [info, setInfo] = useState<{
    user: UserModel;
    photoUrl: string;
    ref: boolean;
    inviterUser?: UserModel;
    refSelfies: string[];
    inviterBalance: number;
  } | null>(null);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [refPay, setRefPay] = useState(true);

  const loadData = () => {
    setLoadingStatus('loading');
    setPhotoIndex(0);
    Api.get<any, any>('/moder/verify/get')
      .then((data) => {
        setInfo(data);
        setLoadingStatus('');
        setRefPay(true);
      })
      .catch((e) => {
        dispatch(toastActions.setToastFail(e.message));
        setLoadingStatus('failed');
      });
  };

  useLayoutEffect(() => {
    loadData();
  }, []);

  const handlePrev = useCallback(() => {
    let nextIndex = photoIndex - 1;
    if (nextIndex < 0) {
      nextIndex = info!.user.photos.length - 1;
    }
    setPhotoIndex(nextIndex);
  }, [photoIndex, info]);

  const handleNext = useCallback(() => {
    let nextIndex = photoIndex + 1;
    if (nextIndex >= info!.user.photos.length) {
      nextIndex = 0;
    }
    setPhotoIndex(nextIndex);
  }, [photoIndex, info]);

  const handleAction = (action) => {
    dispatch(toastActions.setToastLoading());
    Api.post('/moder/verify/action', {
      reason: action,
      userId: info!.user.id,
      payForRef: refPay,
    })
      .then(() => {
        dispatch(toastActions.hideToast());
        loadData();
      })
      .catch((e) => {
        dispatch(toastActions.setToastFail(e.message));
      });
  };

  const handleBlockRef = () => {
    dispatch(toastActions.setToastLoading());
    Api.post('/moder/action', {
      reason: 'ban',
      userId: info!.inviterUser!.id,
    })
      .then(() => {
        dispatch(toastActions.hideToast());
        loadData();
      })
      .catch((e) => {
        dispatch(toastActions.setToastFail(e.message));
      });
  };

  function renderContent() {
    if (loadingStatus === 'failed') {
      return (
        <Placeholder
          icon={<Icon56ErrorOutline />}
          stretched
          header={t('error_title')}
          action={<Button onClick={loadData}>{t('error_button')}</Button>}
        >
          {t('error_text')}
        </Placeholder>
      );
    }

    if (loadingStatus === 'loading' || !info) {
      return <Placeholder stretched>Loading...</Placeholder>;
    }

    const user = info.user;
    return (
      <div className="ProfileCard__wrap">
        <div className="ProfileCard">
          <div className="ProfileCard__indicators">
            {user.photos.map((_, i) => (
              <div
                key={i}
                className={classNames({
                  ProfileCard__indicators__item: true,
                  active: photoIndex === i,
                })}
              />
            ))}
          </div>
          <div className="ProfileCard__photos">
            {user.photos.map((url, i) => (
              <div
                key={url}
                className={classNames({
                  ProfileCard__photo: true,
                  active: i === photoIndex,
                })}
                style={{ backgroundImage: `url(${url})` }}
              />
            ))}
          </div>
          <div className="ProfileCard__touch_zones">
            <div className="ProfileCard__touch_zones__left" onClick={handlePrev} />
            <div className="ProfileCard__touch_zones__right" onClick={handleNext} />
          </div>
        </div>
        <div className="ProfileCard__info">
          {user.danger && (
            <div className="ProfileCard__danger">
              Подозрительный пользователь, будьте внимательны!
            </div>
          )}
          <div className="ProfileCard__info__name_wrap">
            <div className="ProfileCard__info__name">
              {user.name}
              {user.age > 0 ? `, ${user.age}` : ''}
            </div>
            {user.premium && (
              <div className="ProfileCard__info__premium">
                <IcStar28 />
                <div className="ProfileCard__info__premium__label">Premium</div>
              </div>
            )}
            <div
              className={classNames({
                [s.gender]: true,
                [s.genderWoman]: user.sex === 1,
              })}
            >
              {user.sex === 1 ? 'Woman' : 'Men'}
            </div>
          </div>
          {user.distance > 0 && (
            <div className="ProfileCard__distance">
              <IcLocation28 />
              {transformUserDistance(user.distance)}
            </div>
          )}
          {user.lookingFor > 0 && (
            <div className="ProfileCard__looking_for_wrap">
              <div className="ProfileCard__looking_for">
                <div className="ProfileCard__looking_for__smile">
                  {getLookingForSmile(user.lookingFor)}
                </div>
                <div className="ProfileCard__looking_for__cont">
                  <div className="ProfileCard__looking_for__label">
                    {t('profile_looking_for')}
                  </div>
                  <div className="ProfileCard__looking_for__value">
                    {t(getLookingForLabel(user.lookingFor))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {!!user.bio && (
            <div className="ProfileCard__section">
              <div className="ProfileCard__section__title">{t('profile_card_bio')}</div>
              <div className="ProfileCard__section__text">{user.bio}</div>
            </div>
          )}
          {info.ref && (
            <>
              <div className={s.refWrap}>
                <Group>
                  <List>
                    <Cell
                      indicator={
                        <Switch checked={refPay} onClick={() => setRefPay(!refPay)} />
                      }
                    >
                      Реферальная выплата
                    </Cell>
                  </List>
                </Group>
              </div>
              <Group header="Селфи других приглашенных">
                <div className={s.otherRefSelfies}>
                  {info.refSelfies.map((item) => (
                    <div key={item} className={s.otherRefSelfie}>
                      <div
                        className={s.otherRefSelfieImg}
                        style={{ backgroundImage: `url(${item})` }}
                      />
                    </div>
                  ))}
                </div>
              </Group>
              {!!info.inviterUser && (
                <Group header="Пригласивший">
                  <List>
                    <Cell>
                      #{info.inviterUser.id} {info.inviterUser.name}
                    </Cell>
                    <Cell indicator={info.inviterBalance}>Баланс</Cell>
                    {info.inviterUser.name !== 'BANNED' && (
                      <VKCellButton onClick={handleBlockRef}>Заблокировать</VKCellButton>
                    )}
                  </List>
                </Group>
              )}
            </>
          )}
          <div className={s.actions}>
            <Button
              appearance="negative"
              size="l"
              stretched
              onClick={() => handleAction('decline')}
            >
              Decline
            </Button>
            <Button
              mode="secondary"
              size="l"
              stretched
              onClick={() => handleAction('need_fix')}
            >
              Need Fix
            </Button>
            <Button size="l" stretched onClick={() => handleAction('approve')}>
              Approve
            </Button>
          </div>
          <div>
            <InlineHeader>Selfie</InlineHeader>
            <div className={s.selfie}>
              <img className={s.selfieImg} src={info.photoUrl} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <ScrollView>{renderContent()}</ScrollView>
    </>
  );
};
