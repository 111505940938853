import s from './Invite.module.sass';

import { FC, useEffect, useState } from 'react';
import {
  Banner,
  Button,
  Cell,
  Group,
  Header,
  Headline,
  List,
  Placeholder,
} from '@vkontakte/vkui';
import { MainButton, ScrollView } from '$uikit';
import { useCurrentUser, useTranslator } from '$hooks';
import clipboardCopy from 'clipboard-copy';
import { useDispatch } from 'react-redux';
import { toastActions } from '$store/toast';
import { Api } from '$api';
import { Loader, LottieIcon } from '$shared/components';
import { Icon56ErrorOutline } from '@vkontakte/icons';
import WebApp from '@twa-dev/sdk';
import { statReachGoal } from '$utils';
import { useRouter } from 'react-router5';
import { INVITE_PAYOUT } from '$shared/constants/pages';
import { getLanguage } from '$translation';

export const Invite: FC = () => {
  const t = useTranslator();
  const user = useCurrentUser();
  const dispatch = useDispatch();
  const [loadingStatus, setLoadingStatus] = useState('loading');
  const [data, setData] = useState({
    balance: 0,
    reward: 0,
    rewardUsd: 0,
    rewardRub: 0,
    invitedCount: 0,
    verifiedCount: 0,
    hasPayout: false,
  });
  const router = useRouter();
  const lang = getLanguage();

  const loadData = () => {
    setLoadingStatus('loading');
    Api.get<any, any>('/invite')
      .then((resp) => {
        setData(resp);
        setLoadingStatus('');
      })
      .catch((e) => {
        setLoadingStatus('failed');
        dispatch(toastActions.setToastFail(e.message));
      });
  };

  useEffect(() => {
    statReachGoal('invite_open');
    loadData();
  }, []);

  const link = `t.me/nzlchatbot?start=ref_${user?.id ?? 0}`;
  const handleCopy = () => {
    clipboardCopy('https://' + link);
    dispatch(toastActions.setToastSuccess(t('invite_link_copied')));
    statReachGoal('invite_link_copy');
  };

  const handleWallet = () => {
    WebApp.openTelegramLink('https://t.me/wallet');
  };

  const handleTon = () => {
    WebApp.openLink('https://ton.org');
  };

  function renderContent() {
    if (loadingStatus === 'loading') {
      return <Loader isWrapped />;
    }

    if (loadingStatus === 'failed') {
      return (
        <Placeholder
          icon={<Icon56ErrorOutline />}
          stretched
          header={t('error_title')}
          action={<Button onClick={loadData}>{t('error_button')}</Button>}
        >
          {t('error_text')}
        </Placeholder>
      );
    }

    return (
      <ScrollView>
        <div className={s.placeholderWrap}>
          <Placeholder
            withPadding={false}
            icon={<LottieIcon name="duck_money" loop size={84} />}
            header={
              <Header size="regular" multiline>
                {t('invite_title', {
                  reward: lang === 'ru' ? `${data.rewardRub}₽` : `$${data.rewardUsd}`,
                })}
              </Header>
            }
          ></Placeholder>
        </div>
        <div className={s.inviteWrap}>
          <div className={s.inviteLink} onClick={handleCopy}>
            <div className={s.inviteLinkLabel}>{t('invite_link')}</div>
            <div className={s.inviteLinkValue}>{link}</div>
            <div className={s.inviteLinkCopy}>
              <Button>{t('invite_link_copy')}</Button>
            </div>
          </div>
        </div>
        <div className={s.inviteLinkCaption}>{t('invite_link_info')}</div>
        <Group
          header={<Header>{t('invite_payout_title')}</Header>}
          description={t('invite_payout_caption')}
        >
          <List>
            <Cell indicator={`${data.balance} TON`}>{t('invite_payout_avail')}</Cell>
            <Cell indicator={data.invitedCount}>{t('invite_payout_invited_count')}</Cell>
            <Cell indicator={data.verifiedCount}>
              {t('invite_payout_verified_count')}
            </Cell>
          </List>
        </Group>
        {data.hasPayout && (
          <Banner
            header={t('invite_payout_in_progress')}
            text={t('invite_payout_in_progress_caption')}
          />
        )}
        {!data.hasPayout && (
          <MainButton
            onClick={() => {
              router.navigate(INVITE_PAYOUT);
            }}
            text={`${t('invite_payout_btn')}${
              data.balance < 2 ? t('invite_payout_btn_disabled') : ''
            }`}
            disabled={data.balance < 2}
          />
        )}
      </ScrollView>
    );
  }

  return <>{renderContent()}</>;
};
