import './index.sass';

import 'core-js/es';
import 'url-search-params-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { RouterProvider } from 'react-router5';
import { Provider as StoreProvider } from 'react-redux';
import '@vkontakte/vkui/dist/vkui.css';
import * as amplitude from '@amplitude/analytics-browser';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';

import App from './core/App';
import { store } from '$store/store';
import { TranslatorProvider } from '$translation';
import router from 'navigation/router';
import { CARDS } from '$shared/constants/pages';
import bridge from '@vkontakte/vk-bridge';
import { statReachGoal } from '$utils';
import WebApp from '@twa-dev/sdk';

const rollbarConfig = {
  accessToken: '0ce2d1f70b5a4dea8960d1d7f59d246f',
  environment: 'prod',
  captureUncaught: true,
  captureUnhandledRejections: true,
  captureIp: false,
};

// https://localhost:3001/?profileId=185785

window.initUrl = window.location.href;

const urlParams = new URLSearchParams(window.location.search);
const profileId = urlParams.get('profileId');
window.sharedProfileId = profileId ? +profileId : 0;

let initialSearch = window.location.search;
window.isVK = !!initialSearch.match(/vk_user_id/);
window.VkID = +(urlParams.get('vk_user_id') || 0);

window.defaultTab = urlParams.get('defaultTab') || CARDS;
window.startAction = urlParams.get('startAction') || '';
window.isFromMenu = urlParams.get('from_menu') === '1';
if (window.isVK) {
  try {
    window.vkPlatform = urlParams.get('vk_platform') || '';

    const hashParams = new URLSearchParams(window.location.hash.replace('#', '?'));
    window.defaultTab = hashParams.get('defaultTab') || CARDS;
    window.startAction = hashParams.get('startAction') || '';

    if (window.location.hash) {
      window.vkAdsTarget = hashParams.get('target') || '';
    }
  } catch (e) {}

  WebApp.showConfirm = (text: string, callback: any) => {
    if (confirm(text)) {
      callback(true);
    } else {
      callback(false);
    }
  };

  WebApp.showAlert = (text: string) => {
    alert(text);
  };
}

if (!window.isVK) {
  amplitude.init('5a2662bf10056834e20b8fc0f11b3fc7');
}

router.start();

try {
  Telegram.WebApp.ready();
  WebApp.setHeaderColor('secondary_bg_color');
  WebApp.setBackgroundColor('secondary_bg_color');
} catch (e) {}

try {
  bridge.send('VKWebAppInit');
  bridge.subscribe((e) => {
    if (e.detail.type === 'VKWebAppBannerAdUpdated') {
      if (e.detail.data && e.detail.data.result) {
        statReachGoal('show_banner_ads');
      }
    }
  });
} catch (e) {}

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    {/* @ts-ignore */}
    <RollbarProvider config={rollbarConfig}>
      {/* @ts-ignore */}
      <ErrorBoundary>
        <RouterProvider router={router}>
          <TranslatorProvider>
            <StoreProvider {...{ store }}>
              <App />
            </StoreProvider>
          </TranslatorProvider>
        </RouterProvider>
      </ErrorBoundary>
    </RollbarProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
