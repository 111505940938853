import { createSlice } from '@reduxjs/toolkit';

import { ToastActivity, ToastState, SetToastAction } from './interface';
import { t } from '$translation/helper';

const initialState: ToastState = {
  label: '',
  activity: null,
};

const prohibitedFailMessages = ['access_token is required'];

export const { reducer, actions } = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToastLoading(state, action: SetToastAction) {
      state.activity = ToastActivity.LOADING;
      state.label = action.payload || t('toast_loading');
    },
    setToastSuccess(state, action: SetToastAction) {
      state.activity = ToastActivity.SUCCESS;
      state.label = action.payload || t('toast_success');
    },
    setToastFail(state, action: SetToastAction) {
      if (action.payload && prohibitedFailMessages.includes(action.payload)) {
        return;
      }

      state.activity = ToastActivity.FAIL;

      if (action.payload) {
        state.label =
          typeof action.payload === 'string'
            ? action.payload
            : JSON.stringify(action.payload);
      } else {
        state.label = t('toast_failed');
      }
    },
    hideToast(state) {
      state.label = '';
      state.activity = null;
    },
  },
});
