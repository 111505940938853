import { FC, useCallback, useLayoutEffect, useState } from 'react';
import { InlineHeader, ScrollView } from '$uikit';
import { Loader } from '$shared/components';
import { Api } from '$api';
import { useDispatch } from 'react-redux';
import { toastActions } from '$store/toast';
import { UserModel } from '$store/models';
import { Button, Placeholder } from '@vkontakte/vkui';
import { Icon56ErrorOutline } from '@vkontakte/icons';
import { useTranslator } from '$hooks';
import {
  classNames,
  getLookingForLabel,
  getLookingForSmile,
  transformUserDistance,
} from '$utils';
import { IcLocation28, IcStar28 } from '$assets';
import s from './Moder.module.sass';

export const Moder: FC = () => {
  const t = useTranslator();
  const dispatch = useDispatch();
  const [loadingStatus, setLoadingStatus] = useState('loading');
  const [info, setInfo] = useState<{
    user: UserModel;
    messages: string[];
  } | null>(null);
  const [photoIndex, setPhotoIndex] = useState(0);

  const loadData = () => {
    setLoadingStatus('loading');
    setPhotoIndex(0);
    Api.get<any, any>('/moder/get')
      .then((data) => {
        setInfo(data);
        setLoadingStatus('');
      })
      .catch((e) => {
        dispatch(toastActions.setToastFail(e.message));
        setLoadingStatus('failed');
      });
  };

  useLayoutEffect(() => {
    loadData();
  }, []);

  const handlePrev = useCallback(() => {
    let nextIndex = photoIndex - 1;
    if (nextIndex < 0) {
      nextIndex = info!.user.photos.length - 1;
    }
    setPhotoIndex(nextIndex);
  }, [photoIndex, info]);

  const handleNext = useCallback(() => {
    let nextIndex = photoIndex + 1;
    if (nextIndex >= info!.user.photos.length) {
      nextIndex = 0;
    }
    setPhotoIndex(nextIndex);
  }, [photoIndex, info]);

  const handleAction = (action) => {
    dispatch(toastActions.setToastLoading());
    Api.post('/moder/action', {
      reason: action,
      userId: info!.user.id,
    })
      .then(() => {
        dispatch(toastActions.hideToast());
        loadData();
      })
      .catch((e) => {
        dispatch(toastActions.setToastFail(e.message));
      });
  };

  function renderContent() {
    if (loadingStatus === 'failed') {
      return (
        <Placeholder
          icon={<Icon56ErrorOutline />}
          stretched
          header={t('error_title')}
          action={<Button onClick={loadData}>{t('error_button')}</Button>}
        >
          {t('error_text')}
        </Placeholder>
      );
    }

    if (loadingStatus === 'loading' || !info) {
      return <Placeholder stretched>Loading...</Placeholder>;
    }

    const user = info.user;
    return (
      <div className="ProfileCard__wrap">
        <div className="ProfileCard">
          <div className="ProfileCard__indicators">
            {user.photos.map((_, i) => (
              <div
                key={i}
                className={classNames({
                  ProfileCard__indicators__item: true,
                  active: photoIndex === i,
                })}
              />
            ))}
          </div>
          <div className="ProfileCard__photos">
            {user.photos.map((url, i) => (
              <div
                key={url}
                className={classNames({
                  ProfileCard__photo: true,
                  active: i === photoIndex,
                })}
                style={{ backgroundImage: `url(${url})` }}
              />
            ))}
          </div>
          <div className="ProfileCard__touch_zones">
            <div className="ProfileCard__touch_zones__left" onClick={handlePrev} />
            <div className="ProfileCard__touch_zones__right" onClick={handleNext} />
          </div>
        </div>
        <div className="ProfileCard__info">
          {user.danger && (
            <div className="ProfileCard__danger">
              Подозрительный пользователь, будьте внимательны!
            </div>
          )}
          <div className="ProfileCard__info__name_wrap">
            <div className="ProfileCard__info__name">
              {user.name}
              {user.age > 0 ? `, ${user.age}` : ''}
            </div>
            {user.premium && (
              <div className="ProfileCard__info__premium">
                <IcStar28 />
                <div className="ProfileCard__info__premium__label">Premium</div>
              </div>
            )}
          </div>
          {user.distance > 0 && (
            <div className="ProfileCard__distance">
              <IcLocation28 />
              {transformUserDistance(user.distance)}
            </div>
          )}
          {user.lookingFor > 0 && (
            <div className="ProfileCard__looking_for_wrap">
              <div className="ProfileCard__looking_for">
                <div className="ProfileCard__looking_for__smile">
                  {getLookingForSmile(user.lookingFor)}
                </div>
                <div className="ProfileCard__looking_for__cont">
                  <div className="ProfileCard__looking_for__label">
                    {t('profile_looking_for')}
                  </div>
                  <div className="ProfileCard__looking_for__value">
                    {t(getLookingForLabel(user.lookingFor))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {!!user.bio && (
            <div className="ProfileCard__section">
              <div className="ProfileCard__section__title">{t('profile_card_bio')}</div>
              <div className="ProfileCard__section__text">{user.bio}</div>
            </div>
          )}
          <div className={s.actions}>
            <Button
              appearance="negative"
              size="l"
              stretched
              onClick={() => handleAction('ban')}
            >
              Ban
            </Button>
            <Button size="l" stretched onClick={() => handleAction('skip')}>
              Skip
            </Button>
          </div>
          <div>
            <InlineHeader>Messages</InlineHeader>
            <div>
              {info.messages.map((m, i) => (
                <div key={i} className={s.message}>
                  {m}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <ScrollView>{renderContent()}</ScrollView>
    </>
  );
};
