import './Loader.sass';

import React, { FC } from 'react';

import { classNames } from '$utils';
import { LoaderProps } from './Loader.interface';

export const Loader: FC<LoaderProps> = (props) => {
  const { mode = 'default', isWrapped = false, className } = props;
  const loader = (
    <div
      className={classNames({
        Loader: true,
        [mode]: true,
      })}
    />
  );

  if (isWrapped) {
    return <div className={classNames('Loader__wrap', className)}>{loader}</div>;
  }

  return loader;
};
