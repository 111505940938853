import s from './CustomCard.module.sass';
import { FC, useCallback } from 'react';
import { AdCardProps } from '$core/Cards/AdCard/AdCard.interface';
import { useDispatch, useSelector } from 'react-redux';
import { cardsSelector } from '$store/cards';
import WebApp from '@twa-dev/sdk';
import { Api } from '$api';
import { toastActions } from '$store/toast';
import { IcClose28 } from '$assets';
import { classNames } from '$utils';

export const CustomCard: FC<AdCardProps> = (props) => {
  const { adId, elemRef, makeSwipe } = props;
  const { ads } = useSelector(cardsSelector);
  const ad = ads[adId];
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    if (ad.telegramLink) {
      dispatch(toastActions.setToastLoading());
      Api.get<any, any>(ad.url + '&ret=1')
        .then((data) => {
          dispatch(toastActions.hideToast());
          WebApp.openTelegramLink(data.url);
        })
        .catch((e) => {
          dispatch(toastActions.setToastFail(e.message));
        });
    } else {
      WebApp.openLink(ad.url);
    }
  }, [ad]);

  return (
    <div className="Card custom" ref={elemRef}>
      <div className="Card_photos">
        <div
          className="Card_photo active"
          style={{ backgroundImage: `url(${ad.photoUrl})` }}
        />
      </div>
      <div className="Card__info_wrap">
        <div className="Card__info">
          <div className="Card__info__content">
            <div className="Card__info__caption">{ad.text}</div>
          </div>
        </div>
        <div className={s.buttons}>
          <div className={s.button} onClick={handleClick}>
            {ad.buttonText}
          </div>
          <div
            className={classNames({
              [s.button]: true,
              [s.buttonSecondary]: true,
            })}
            onClick={() => makeSwipe(1)}
          >
            <IcClose28 />
          </div>
        </div>
      </div>
    </div>
  );
};
