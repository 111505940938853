import s from './Match.module.sass';

import { FC, useCallback } from 'react';
import { useRoute, useRouter } from 'react-router5';
import { Button, PanelHeader, PanelHeaderBack } from '@vkontakte/vkui';
import { classNames } from '$utils';
import { useCurrentUser, useTranslator, useUser } from '$hooks';
import { goBack } from '$navigation/router';
import { CHAT } from '$shared/constants/pages';

export const Match: FC = () => {
  const { route } = useRoute();
  const userId = +route.params.id;
  const t = useTranslator();
  const router = useRouter();

  const user = useUser(userId);
  const curUser = useCurrentUser();

  const handleMatchPress = useCallback(() => {
    router.navigate(CHAT, { peerId: userId });
  }, [userId]);

  return (
    <>
      {window.isVK && (
        <PanelHeader before={<PanelHeaderBack onClick={() => goBack()} />} />
      )}
      <div className={s.wrap}>
        <div className={s.cont}>
          <div className={s.photos}>
            <div className={s.photosIcon} />
            <div
              className={classNames({
                [s.photo]: true,
                [s.photoFirst]: true,
              })}
              style={{ backgroundImage: `url(${user?.photos[0]})` }}
            />
            <div
              className={classNames({
                [s.photo]: true,
                [s.photoLast]: true,
              })}
              style={{ backgroundImage: `url(${curUser?.photos[0]})` }}
            />
          </div>
          <div className={s.title}>{t('match_title')}</div>
          <div className={s.description}>
            {t('match_text', {
              name: user?.name ?? '',
            })}
          </div>
        </div>
        <div className={s.footer}>
          <Button stretched size="l" onClick={handleMatchPress}>
            {t('match_message')}
          </Button>
          <Button
            stretched
            size="l"
            mode="secondary"
            style={{ marginTop: 10 }}
            onClick={() => goBack()}
          >
            {t('match_back')}
          </Button>
        </div>
      </div>
    </>
  );
};
