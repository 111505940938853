import s from './Paywall.module.sass';

import { FC } from 'react';
import { PanelHeader, PanelHeaderBack, Placeholder } from '@vkontakte/vkui';

import { goBack } from '$navigation/router';
import { LottieIcon } from '$shared/components';

export const Paywall: FC = () => {
  return (
    <>
      {window.isVK && (
        <PanelHeader before={<PanelHeaderBack onClick={() => goBack()} />} />
      )}
      <Placeholder
        icon={<LottieIcon loop name="paywall" />}
        header="Выделяйтесь среди остальных!"
      >
        Девушки увидят вас первыми в поиске и в лайках с помощью Премиум.
      </Placeholder>
    </>
  );
};
