import './ProfileCard.sass';

import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mainActions, mainSelector } from '$store/main';
import { useRoute } from 'react-router5';
import router, { goBack } from '$navigation/router';
import {
  classNames,
  getLookingForLabel,
  getLookingForSmile,
  isIOS,
  transformUserDistance,
} from '$utils';
import {
  Icon24ReportOutline,
  Icon24Share,
  Icon36CancelOutline,
  Icon36LikeOutline,
} from '@vkontakte/icons';
import { toastActions } from '$store/toast';
import { Api } from '$api';
import { MATCH, PREMIUM } from '$shared/constants/pages';
import { useCurrentUser, useTranslator } from '$hooks';
import { Button, PanelHeader, PanelHeaderBack } from '@vkontakte/vkui';
import { IcLocation28, IcStar28 } from '$assets';
import clipboardCopy from 'clipboard-copy';
import bridge from '@vkontakte/vk-bridge';
import { ScrollView } from '$uikit';

export const ProfileCard: FC = () => {
  const dispatch = useDispatch();
  const { route } = useRoute();
  const userId = +route.params.id;
  const skipActions = route.params?.skipActions ?? false;
  const [photoIndex, setPhotoIndex] = useState(0);
  const t = useTranslator();
  const { hasPremium } = useSelector(mainSelector);

  const { users } = useSelector(mainSelector);
  const user = users[userId];
  const curUser = useCurrentUser();

  useEffect(() => {
    if (!user) {
      goBack();
    }
  }, []);

  const handleShare = useCallback(() => {
    const link = `https://t.me/nzlchatbot?start=s_${new Number(userId).toString(16)}`;
    clipboardCopy(link);

    if (window.isVK) {
      bridge
        .send('VKWebAppShare', {
          link,
        })
        .then(() => {})
        .catch(() => {});
    } else {
      Telegram.WebApp.openTelegramLink(
        `https://t.me/share?url=${encodeURIComponent(link)}`,
      );

      // if (navigator.canShare && navigator.canShare({ url: link })) {
      //   navigator.share({
      //     url: link,
      //   });
      // } else {
      //   dispatch(toastActions.setToastSuccess(t('profile_card_link_copied')));
      // }
    }
  }, [userId]);

  const handlePremium = () => {
    if (hasPremium) {
      return;
    }

    if (window.isVK && isIOS()) {
      return;
    }

    router.navigate(PREMIUM);
  };

  const handlePrev = useCallback(() => {
    let nextIndex = photoIndex - 1;
    if (nextIndex < 0) {
      nextIndex = user.photos.length - 1;
    }
    setPhotoIndex(nextIndex);
  }, [photoIndex, user]);

  const handleNext = useCallback(() => {
    let nextIndex = photoIndex + 1;
    if (nextIndex >= user.photos.length) {
      nextIndex = 0;
    }
    setPhotoIndex(nextIndex);
  }, [photoIndex, user]);

  const handleAction = useCallback(
    (reason: number) => {
      dispatch(toastActions.setToastLoading());
      Api.post<any, any>('/likes/resolve', {
        fromId: userId,
        reason,
      })
        .then((res) => {
          dispatch(
            mainActions.moveLike({
              fromId: userId,
              reason,
            }),
          );
          dispatch(toastActions.hideToast());

          if (res.isMatch) {
            router.navigate(MATCH, { id: userId }, { replace: true });
          } else {
            goBack();
          }
        })
        .catch((err) => {
          dispatch(toastActions.setToastFail(err.message));
        });
    },
    [userId],
  );

  const handleReport = () => {
    dispatch(toastActions.setToastLoading());
    Api.post('/report', {
      userId,
    })
      .then(() => {
        dispatch(toastActions.setToastSuccess('Жалоба успешно отправлена!'));
      })
      .catch((e) => {
        dispatch(toastActions.setToastFail(e.message));
      });
  };

  function renderContent() {
    if (!user) {
      return null;
    }

    return (
      <div className="ProfileCard__wrap">
        <div className="ProfileCard">
          <div className="ProfileCard__indicators">
            {user.photos.map((_, i) => (
              <div
                key={i}
                className={classNames({
                  ProfileCard__indicators__item: true,
                  active: photoIndex === i,
                })}
              />
            ))}
          </div>
          <div className="ProfileCard__photos">
            {user.photos.map((url, i) => (
              <div
                key={url}
                className={classNames({
                  ProfileCard__photo: true,
                  active: i === photoIndex,
                })}
                style={{ backgroundImage: `url(${url})` }}
              />
            ))}
          </div>
          <div className="ProfileCard__touch_zones">
            <div className="ProfileCard__touch_zones__left" onClick={handlePrev} />
            <div className="ProfileCard__touch_zones__right" onClick={handleNext} />
          </div>
          {!skipActions && (
            <div className="ProfileCard__bottom">
              <div className="ProfileCard__actions">
                <div
                  className="ProfileCard__actions__item dislike"
                  onClick={() => handleAction(0)}
                >
                  <Icon36CancelOutline />
                </div>
                <div
                  className="ProfileCard__actions__item like"
                  onClick={() => handleAction(1)}
                >
                  <Icon36LikeOutline />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="ProfileCard__info">
          {user.danger && (
            <div className="ProfileCard__danger">
              Подозрительный пользователь, будьте внимательны!
            </div>
          )}
          <div className="ProfileCard__info__name_wrap">
            <div className="ProfileCard__info__name">
              {user.name}
              {user.age > 0 ? `, ${user.age}` : ''}
            </div>
            {user.premium && (
              <div className="ProfileCard__info__premium" onClick={handlePremium}>
                <IcStar28 />
                <div className="ProfileCard__info__premium__label">Premium</div>
              </div>
            )}
          </div>
          {user.distance > 0 && (
            <div className="ProfileCard__distance">
              <IcLocation28 />
              {transformUserDistance(user.distance)}
            </div>
          )}
          {user.lookingFor > 0 && (
            <div className="ProfileCard__looking_for_wrap">
              <div className="ProfileCard__looking_for">
                <div className="ProfileCard__looking_for__smile">
                  {getLookingForSmile(user.lookingFor)}
                </div>
                <div className="ProfileCard__looking_for__cont">
                  <div className="ProfileCard__looking_for__label">
                    {t('profile_looking_for')}
                  </div>
                  <div className="ProfileCard__looking_for__value">
                    {t(getLookingForLabel(user.lookingFor))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {!!user.bio && (
            <div className="ProfileCard__section">
              <div className="ProfileCard__section__title">{t('profile_card_bio')}</div>
              <div className="ProfileCard__section__text">{user.bio}</div>
            </div>
          )}
          <div style={{ marginTop: 24 }}>
            <Button
              mode="secondary"
              size="l"
              stretched
              onClick={handleShare}
              before={<Icon24Share />}
            >
              {t('profile_card_share')}
            </Button>
            {curUser?.id !== userId && (
              <div style={{ marginTop: 12 }}>
                <Button
                  mode="secondary"
                  size="l"
                  stretched
                  onClick={handleReport}
                  before={<Icon24ReportOutline />}
                >
                  Пожаловаться
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {window.isVK && (
        <PanelHeader before={<PanelHeaderBack onClick={() => goBack()} />} />
      )}
      <ScrollView>{renderContent()}</ScrollView>
    </>
  );
};
