import './Card.sass';

import { FC, useCallback, useState } from 'react';

import { useCurrentUser, useTranslator, useUser } from '$hooks';
import cn from 'classnames';
import { forwardRef, useImperativeHandle } from 'react';
import WebApp from '@twa-dev/sdk';
import { toastActions } from '$store/toast';
import { useDispatch, useSelector } from 'react-redux';
import clipboardCopy from 'clipboard-copy';
import {
  IcClose16,
  IcInfo28,
  IcLike28,
  IcLocation28,
  IcRefresh28,
  IcShare28,
  IcStar28,
  IcUser28,
} from '$assets';
import { useRouter } from 'react-router5';
import { PROFILE_CARD } from '$shared/constants/pages';
import {
  classNames,
  getLookingForLabel,
  getLookingForSmile,
  isIOS,
  transformUserDistance,
} from '$utils';
import { cardsActions, dislikedCardsSelector } from '$store/cards';
import bridge from '@vkontakte/vk-bridge';
import s from '$core/Home/Home.module.sass';

// eslint-disable-next-line react/display-name
export const Card = forwardRef<any, any>((props, ref) => {
  const { userId, makeSwipe, elemRef } = props;

  const t = useTranslator();
  const dispatch = useDispatch();
  const user = useUser(userId);
  const curUser = useCurrentUser();
  const router = useRouter();

  const [photoIndex, setPhotoIndex] = useState(0);
  const [progress, setProgress] = useState(100);
  const disliked = useSelector(dislikedCardsSelector);

  const handlePrevPhoto = useCallback(() => {
    setPhotoIndex((prev) => {
      const newIndex = prev - 1;
      if (newIndex < 0) {
        return user.photos.length - 1;
      }
      return newIndex;
    });
    WebApp.HapticFeedback.selectionChanged();
  }, []);

  const handleNextPhoto = useCallback(() => {
    setPhotoIndex((prev) => {
      const newIndex = prev + 1;
      if (newIndex > user.photos.length - 1) {
        return 0;
      }
      return newIndex;
    });
    WebApp.HapticFeedback.selectionChanged();
  }, []);

  const handleCopy = useCallback(() => {
    const link = `https://t.me/nzlchatbot?start=s_${new Number(userId).toString(16)}`;
    clipboardCopy(link);

    if (window.isVK) {
      bridge
        .send('VKWebAppShare', {
          link,
        })
        .then(() => {})
        .catch(() => {});
    } else {
      Telegram.WebApp.openTelegramLink(
        `https://t.me/share?url=${encodeURIComponent(link)}`,
      );

      // if (navigator.canShare && navigator.canShare({ url: link })) {
      //   navigator.share({
      //     url: link,
      //   });
      // } else {
      //   dispatch(toastActions.setToastSuccess(t('profile_card_link_copied')));
      // }
    }
  }, [userId]);

  const handleProfile = useCallback(() => {
    router.navigate(PROFILE_CARD, { id: userId });
  }, [userId]);

  useImperativeHandle(
    ref,
    () => ({
      changePhoto: (direction: 'prev' | 'next') => {
        if (direction === 'next') {
          setPhotoIndex((prev) => {
            const newIndex = prev + 1;
            if (newIndex > user.photos.length - 1) {
              return 0;
            }
            return newIndex;
          });
        } else {
          setPhotoIndex((prev) => {
            const newIndex = prev - 1;
            if (newIndex < 0) {
              return user.photos.length - 1;
            }
            return newIndex;
          });
        }
      },
    }),
    [setPhotoIndex, user.photos, photoIndex],
  );

  function renderDescription() {
    const blocks = [
      (user.bio || '').trim().length > 0 ? 'bio' : null,
      user.lookingFor > 0 ? 'lookingFor' : null,
      user.distance > 0 ? 'distance' : null,
    ].filter(Boolean);
    const blockIndex = photoIndex < blocks.length - 1 ? photoIndex : blocks.length - 1;
    const curBlock = blocks[blockIndex];

    if (curBlock === 'lookingFor') {
      return (
        <div className="Card__info__looking_for_wrap">
          <div className="Card__info__looking_for">
            <div className="Card__info__looking_for__smile">
              {getLookingForSmile(user.lookingFor)}
            </div>
            <div className="Card__info__looking_for__text">
              {t(getLookingForLabel(user.lookingFor))}
            </div>
          </div>
        </div>
      );
    }

    if (curBlock === 'distance') {
      return (
        <div className="Card__location">
          <IcLocation28 />
          {transformUserDistance(user.distance)}
        </div>
      );
    }

    return <div className="Card__info__caption__item three_lines">{user.bio}</div>;
  }

  return (
    <div
      ref={elemRef}
      className={cn({
        Card: true,
        //isLike,
        //isSuperLike,
      })}
    >
      <div className="Card__scrim_top" />
      <div className="Card_photos">
        {user.photos.map((photo, index) => {
          return (
            <div
              key={photo}
              className={cn({
                Card_photo: true,
                active: index === photoIndex,
              })}
              style={{ backgroundImage: `url(${photo})` }}
            />
          );
        })}
      </div>
      <div className="Card_photos__pagination prev" onClick={handlePrevPhoto} />
      <div className="Card_photos__pagination next" onClick={handleNextPhoto} />

      <div className="Card__content">
        <div className="Card__photo_indicator">
          {user.photos.length > 1
            ? user.photos.map((_, i) => (
                <div
                  key={i}
                  className={cn({
                    Card__photo_indicator__item: true,
                    filled: photoIndex > i,
                    active: photoIndex === i,
                  })}
                >
                  <div
                    className="Card__photo_indicator__item_progress"
                    style={
                      photoIndex === i
                        ? {
                            transform: `translateX(${progress}%)`,
                          }
                        : {}
                    }
                  />
                </div>
              ))
            : null}
        </div>
      </div>
      <div
        className={classNames({
          Card__info_wrap: true,
          ['Card__info_wrap_fixPadding']: !window.isFromMenu && isIOS() && !window.isVK,
        })}
      >
        <div className="Card__info" onClick={handleProfile}>
          <div className="Card__info__content">
            <div className="Card__info__name_wrap">
              <div className="Card__info__name">
                {user.name}
                {user.age > 0 ? `, ${user.age}` : ''}
              </div>
              {user.premium && (
                <div className="Card__info__premium">
                  <IcStar28 />
                </div>
              )}
            </div>
            <div className="Card__info__caption">{renderDescription()}</div>
          </div>
          <div className="Card__info_icon">
            <IcInfo28 />
          </div>
        </div>
        <div className="Card__info_actions">
          <div
            className={classNames({
              Card__info_action: true,
              cancel: true,
              disabled: disliked.length === 0,
            })}
            onClick={() => dispatch(cardsActions.cancelAction())}
          >
            <IcRefresh28 />
          </div>
          <div className="Card__info_action large dislike" onClick={() => makeSwipe(1)}>
            <IcClose16 />
          </div>
          <div className="Card__info_action large like" onClick={() => makeSwipe(2)}>
            <IcLike28 />
          </div>
          <div className="Card__info_action" onClick={handleCopy}>
            <IcShare28 />
          </div>
        </div>
      </div>
    </div>
  );
});
