import s from './Likes.module.sass';

import { FC, useCallback, useEffect } from 'react';
import { Placeholder, Spinner, Button, PanelHeader } from '@vkontakte/vkui';
import { Icon56ErrorOutline } from '@vkontakte/icons';
import { useDispatch, useSelector } from 'react-redux';

import { mainActions, mainSelector, premiumRequiredSelector } from '$store/main';
import { useTranslator } from '$hooks';
import { likesActions, likesSelector } from '$store/likes';
import { LottieIcon, PremiumBanner } from '$shared/components';
import { LikeItem } from '$core/Likes/LikeItem/LikeItem';
import router from '$navigation/router';
import { PREMIUM } from '$shared/constants/pages';
import { isIOS } from '$utils';

export const Likes: FC = () => {
  const t = useTranslator();
  const dispatch = useDispatch();
  const { loadingStatus, likes } = useSelector(likesSelector);
  const premiumRequired = useSelector(premiumRequiredSelector);
  const { hasPremium } = useSelector(mainSelector);

  const loadData = useCallback(() => {
    dispatch(likesActions.loadLikes());
  }, []);

  useEffect(() => {
    Telegram.WebApp.expand();
    dispatch(mainActions.setLikesBadgeShown(true));
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const handlePremium = () => {
    router.navigate(PREMIUM);
  };

  function renderContent() {
    if (loadingStatus === 'loading' && likes.length === 0) {
      return (
        <Placeholder stretched>
          <Spinner />
        </Placeholder>
      );
    }

    if (loadingStatus === 'failed') {
      return (
        <Placeholder
          icon={<Icon56ErrorOutline />}
          stretched
          header={t('error_title')}
          action={<Button onClick={loadData}>{t('error_button')}</Button>}
        >
          {t('error_text')}
        </Placeholder>
      );
    }

    if (likes.length === 0) {
      if (hasPremium || (window.isVK && isIOS())) {
        return (
          <Placeholder
            icon={<LottieIcon name="heart" />}
            stretched
            header={t('likes_empty_title')}
          >
            {t('likes_empty')}
          </Placeholder>
        );
      }

      return (
        <Placeholder
          icon={<LottieIcon name="heart" />}
          stretched
          header={t('likes_empty_offer_title')}
          action={
            <Button size="l" onClick={handlePremium}>
              {t('likes_empty_offer_btn')}
            </Button>
          }
        >
          {t('likes_empty_offer')}
        </Placeholder>
      );
    }

    return (
      <>
        <div className={s.likes}>
          {likes.map((like) => (
            <LikeItem key={like.fromId} userId={like.fromId} canBlur />
          ))}
        </div>
      </>
    );
  }

  return (
    <>
      {window.isVK && <PanelHeader>{t('likes_title')}</PanelHeader>}
      {renderContent()}
    </>
  );
};
