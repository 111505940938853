import s from './PremiumInfo.module.sass';
import React, { FC, useEffect, useState } from 'react';
import { Button, PanelHeader, Placeholder } from '@vkontakte/vkui';
import { Api } from '$api';
import { goBack } from '$navigation/router';
import { Loader } from '$shared/components';
import { Icon56ErrorOutline } from '@vkontakte/icons';
import { useTranslator } from '$hooks';
import { format } from '$utils';
import WebApp from '@twa-dev/sdk';
import { useDispatch } from 'react-redux';
import { toastActions } from '$store/toast';
import bridge from '@vkontakte/vk-bridge';

export const PremiumInfo: FC = () => {
  const t = useTranslator();
  const dispatch = useDispatch();
  const [loadingStatus, setLoadingStatus] = useState('loading');
  const [info, setInfo] = useState<{
    price: number;
    currency: string;
    activateAt: number;
    autoCharge: boolean;
  } | null>(null);

  const loadData = () => {
    setLoadingStatus('loading');
    Api.get<any, any>('/account/premium')
      .then((data) => {
        if (data.hasPremium) {
          setLoadingStatus('');
          setInfo(data);
        } else {
          goBack();
        }
      })
      .catch(() => {
        setLoadingStatus('failed');
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const handleCancel = () => {
    WebApp.showConfirm(t('premium_info_cancel_confirm'), (confirmed) => {
      if (confirmed) {
        dispatch(toastActions.setToastLoading());
        Api.delete('/account/premium/cancel')
          .then(() => {
            dispatch(toastActions.setToastSuccess());
            goBack();
          })
          .catch((e) => {
            dispatch(toastActions.setToastFail(e.message));
          });
      }
    });
  };

  function renderContent() {
    if (loadingStatus == 'failed') {
      return (
        <Placeholder
          icon={<Icon56ErrorOutline />}
          stretched
          header={t('error_title')}
          action={<Button onClick={loadData}>{t('error_button')}</Button>}
        >
          {t('error_text')}
        </Placeholder>
      );
    }

    if (loadingStatus === 'loading' || !info) {
      return <Loader isWrapped />;
    }

    if (window.isVK) {
      return (
        <div className={s.wrap}>
          <div className={s.info}>Премиум активен.</div>
          <div className={s.charge}>
            <Button
              mode="secondary"
              href="https://vk.com/settings?act=payments&section=subscriptions"
              target="_blank"
            >
              {t('premium_info_cancel')}
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div className={s.wrap}>
        <div className={s.info}>
          {t('premium_info_text', {
            date: format(info.activateAt * 1000, 'dd MMMM yyyy'),
          })}{' '}
          {!window.isVK &&
            (info.autoCharge ? t('premium_info_active') : t('premium_info_cancelled'))}
        </div>
        {!window.isVK && info.autoCharge && (
          <div className={s.charge}>
            <Button mode="secondary" onClick={handleCancel}>
              {t('premium_info_cancel')}
            </Button>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      {window.isVK && <PanelHeader>{t('premium_info_title')}</PanelHeader>}
      {renderContent()}
    </>
  );
};
