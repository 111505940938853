import './CardsSwiper.sass';

import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { animated, interpolate, useSpring } from 'react-spring';
import { useDrag } from '@use-gesture/react';

import { CardsSwiperProps } from './CardsSwiper.interface';
import { Card } from '../Card/Card';
import { useDispatch } from 'react-redux';
import { cardsActions } from '$store/cards';
import { CustomCard } from '$core/Cards/AdCard/CustomCard';

export const CardsSwiper: FC<CardsSwiperProps> = (props) => {
  const { cards, isFromProfile = false } = props;
  const dispatch = useDispatch();

  const cardRef = useRef<any>(null);
  const cardElemRef = useRef<HTMLDivElement>(null);
  const [isDragging, setDragging] = useState(false);
  const [{ x, y }, set] = useSpring(() => ({ x: 0, y: 0 }));

  const firstCards = useMemo(() => {
    return cards.slice(0, 2);
  }, [cards]);

  useEffect(() => {
    const handleTouchMove = (e) => {
      e.preventDefault();
    };
    const element = cardElemRef.current;
    if (!element) {
      return;
    }

    element.addEventListener('touchmove', handleTouchMove, { passive: false });
    return () => {
      element.removeEventListener('touchmove', handleTouchMove);
    };
  }, [cardElemRef.current, firstCards]);

  useDrag(
    (params) => {
      const {
        down,
        elapsedTime,
        movement: [mx],
        cancel,
        canceled,
        event,
        tap,
        first,
        xy,
      } = params;

      event.preventDefault();
      event.stopPropagation();

      if (!down) {
        let x = 0;
        if (Math.abs(mx) > window.innerWidth * 0.2 && !isFromProfile) {
          x = window.innerWidth * (mx > 0 ? 1 : -1);
          setTimeout(() => {
            set({ x: 0, config: { duration: 0 } });
            dispatch(
              cardsActions.swipe({
                userId: cards[0],
                reason: mx > 0 ? 2 : 1,
              }),
            );
            //cardsActions.swipe(cards[0], mx > 0 ? 2 : 1, { isFromMatchTime });
          }, 150);
        }

        if (!isFromProfile) {
          set({ x, config: { duration: 150 } });
          setDragging(false);
        }
      } else {
        if (!isFromProfile) {
          set({ x: mx, config: { duration: 0 } });

          if (!isDragging) {
            setDragging(true);
          }
        }

        if (Math.abs(mx) < 10 && elapsedTime < 1000 && first) {
          // longPressTimer.current = setTimeout(() => {
          //   cancel();
          //   setTimeout(() => actions.toggleUI(false));
          // }, 700);
        }
      }
    },
    {
      enabled: true,
      pointer: {
        touch: true,
        capture: true,
        //lock: true,
      },
      eventOptions: {
        passive: false,
        capture: false,
      },
      filterTaps: false,
      preventScroll: true,
      threshold: 0,
      target: cardElemRef,
    },
  );

  const makeSwipe = (reason: number) => {
    set({ x: window.innerWidth * (reason === 2 ? 1 : -1), config: { duration: 150 } });
    setTimeout(() => {
      set({ x: 0, config: { duration: 0 } });
      dispatch(
        cardsActions.swipe({
          userId: cards[0],
          reason,
        }),
      );
    }, 150);
  };

  return (
    <>
      <div className="CardsSwiper">
        {firstCards.map((card, i) => {
          let params: any = {};
          let ref: any;
          if (i === 0) {
            ref = cardRef;
            params = {
              ...params,
              style: {
                // @ts-ignore
                transform: interpolate([x, y], (x, y) => {
                  return `translate(${x}px, ${y}px)`;
                }),
                touchAction: 'pan-x',
                zIndex: 3,
              },
              //...bind2(),
            };
          } else {
            params = {
              ...params,
              style: {
                // @ts-ignore
                transform: interpolate([x, y], (x, y) => {
                  let val, toValue;
                  if (Math.abs(x) > Math.abs(y)) {
                    val = x;
                    toValue = window.innerWidth;
                  } else {
                    val = y;
                    toValue = window.innerHeight;
                  }
                  const percent = Math.abs(val) / toValue;
                  let scale = 0.8 + 0.2 * percent;
                  return `scale(${scale})`;
                }),
              },
            };
          }

          return (
            <animated.div className="CardsSwiper__card" key={card} {...params}>
              {card > 0 ? (
                <Card
                  userId={card}
                  ref={ref}
                  elemRef={i === 0 ? cardElemRef : null}
                  makeSwipe={makeSwipe}
                />
              ) : (
                <CustomCard
                  adId={-card}
                  makeSwipe={makeSwipe}
                  elemRef={i === 0 ? cardElemRef : null}
                />
              )}
            </animated.div>
          );
        })}
      </div>
    </>
  );
};
