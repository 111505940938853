import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  FormLayout,
  FormItem,
  RangeSlider,
  SegmentedControl,
  Button,
  Placeholder,
  Spinner,
  Slider,
  PanelHeader,
  PanelHeaderBack,
  Switch,
  Cell,
} from '@vkontakte/vkui';
import { useDispatch } from 'react-redux';

import { useTranslator } from '$hooks';
import { toastActions } from '$store/toast';
import { Api } from '$api';
import { HOME } from '$shared/constants/pages';
import { useRouter } from 'react-router5';
import { MainButton, ScrollView } from '$uikit';
import { goBack } from '$navigation/router';
import { batchActions } from '$store';
import { cardsActions } from '$store/cards';

export const Filters: FC = () => {
  const t = useTranslator();
  const dispatch = useDispatch();
  const [sex, setSex] = useState(0);
  const [age, setAge] = useState<[number, number]>([17, 25]);
  const [loadingStatus, setLoadingStatus] = useState('loading');
  const [radius, setRadius] = useState(0);
  const [onlyPremium, setOnlyPremium] = useState(false);
  const router = useRouter();
  const [isSaving, setSaving] = useState(false);
  const [location, setLocation] = useState({
    address: '',
    customGeoName: '',
  });

  const loadData = useCallback(() => {
    setLoadingStatus('loading');
    Api.get<any, any>('/account/filters')
      .then((data) => {
        setSex(data.sex);
        setAge(data.age);
        setRadius(data.radius);
        setOnlyPremium(data.onlyPremium);
        setLocation({
          address: data.address,
          customGeoName: data.customGeoName,
        });
        setLoadingStatus('');
      })
      .catch((err) => {
        dispatch(toastActions.setToastFail(err.message));
        setTimeout(loadData, 3000);
      });
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const ageCaption = useMemo(() => {
    let components: any[] = [];

    if (age[0] < 18) {
      components = [t('filters_age_any')];
    } else {
      components = [age[0]];
    }

    if (age[1] < 80) {
      components.push(age[1]);
    } else {
      components.push(t('filters_age_any'));
    }

    return components.join(' - ');
  }, [age, t]);

  const radiusCaption = useMemo(() => {
    if (radius >= 100 * 1000) {
      return t('filters_radius_any');
    }

    return `${radius / 1000}km`;
  }, [radius, t]);

  const handleSave = () => {
    setSaving(true);

    Api.post('/account/filters/save', {
      sex: +sex,
      ageFrom: age[0],
      ageTo: age[1],
      radius,
    })
      .then(() => {
        setSaving(false);
        dispatch(batchActions(toastActions.hideToast(), cardsActions.clear()));
        goBack();
      })
      .catch((err) => {
        setSaving(false);
        dispatch(toastActions.setToastFail(err.message));
      });
  };

  function renderContent() {
    if (loadingStatus === 'loading') {
      return (
        <Placeholder stretched>
          <Spinner />
        </Placeholder>
      );
    }

    return (
      <>
        <FormLayout>
          {/*<FormItem top="Место">*/}
          {/*  <Cell>{location.customGeoName || location.address}</Cell>*/}
          {/*</FormItem>*/}
          <FormItem top={t('filters_sex')}>
            <SegmentedControl
              name="sex"
              value={sex}
              onChange={(value) => setSex(parseInt(value + '', 10))}
              options={[
                {
                  label: t('filters_sex_female'),
                  value: 1,
                },
                {
                  label: t('filters_sex_male'),
                  value: 2,
                },
                {
                  label: t('filters_sex_any'),
                  value: 3,
                },
              ]}
            />
          </FormItem>
          <FormItem top={t('filters_age')} bottom={ageCaption}>
            <RangeSlider min={17} max={80} step={1} value={age} onChange={setAge} />
          </FormItem>
          {/*<FormItem top={t('filters_radius')} bottom={radiusCaption}>*/}
          {/*  <Slider*/}
          {/*    min={100}*/}
          {/*    max={1000 * 100}*/}
          {/*    step={100}*/}
          {/*    value={radius}*/}
          {/*    onChange={setRadius}*/}
          {/*  />*/}
          {/*</FormItem>*/}
          {/*<FormItem*/}
          {/*  top="Премиум"*/}
          {/*  bottom="Более безопасные пары с пользователями, которые приобрели премиум доступ."*/}
          {/*>*/}
          {/*  <Cell*/}
          {/*    after={*/}
          {/*      <Switch*/}
          {/*        checked={onlyPremium}*/}
          {/*        onClick={(e) => {*/}
          {/*          setOnlyPremium(!onlyPremium);*/}
          {/*        }}*/}
          {/*      />*/}
          {/*    }*/}
          {/*    onClick={(e) => {*/}
          {/*      // @ts-ignore*/}
          {/*      if (e.target && e.target.closest('.Switch')) return;*/}
          {/*      setOnlyPremium(!onlyPremium);*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    Только премиум пользователи*/}
          {/*  </Cell>*/}
          {/*</FormItem>*/}
          <MainButton onClick={handleSave} text={t('save')} progress={isSaving} />
        </FormLayout>
      </>
    );
  }

  return (
    <>
      {window.isVK && (
        <PanelHeader before={<PanelHeaderBack onClick={() => goBack()} />}>
          {t('filters_title')}
        </PanelHeader>
      )}
      <ScrollView>{renderContent()}</ScrollView>
    </>
  );
};
