export const EDIT = 'edit';
export const LIKES = 'likes';
export const SETTINGS = 'settings';
export const PROFILE_CARD = 'profile_card';
export const FILTERS = 'filters';
export const DEBUG = 'DEBUG';
export const HOME = 'home';
export const OTHER = 'other';
export const ACTIVITY = 'activity';
export const CARDS = 'cards';
export const MATCH = 'match';
export const CHAT = 'chat';

export const JOIN = 'join';
export const IN_VERIFICATION = 'IN_VERIFICATION';
export const DECLINED = 'DECLINED';

export const PREMIUM = 'premium';

export const PREMIUM_INFO = 'premium_info';

export const LIKES_LIMIT = 'likes_limit';

export const PAYWALL = 'paywall';

export const INVITE = 'invite';

export const FRIENDS = 'FRIENDS';

export const MODER = 'MODER';
export const MODER_VERIFY = 'MODER_VERIFY';
export const INVITE_PAYOUT = 'INVITE_PAYOUT';
