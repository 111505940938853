import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Api } from '$api';
import { toastActions } from '$store/toast';
import { FormItem, FormLayout, Input } from '@vkontakte/vkui';
import { MainButton } from '$uikit';
import WebApp from '@twa-dev/sdk';
import { goBack } from '$navigation/router';

export const InvitePayout: FC = () => {
  const dispatch = useDispatch();
  const [sending, setSending] = useState(false);
  const [wallet, setWallet] = useState('');

  const handleSubmit = () => {
    const walletTrimmed = wallet.trim();
    if (walletTrimmed.length < 10) {
      dispatch(toastActions.setToastFail('Введите адрес кошелька'));
      return;
    }

    WebApp.showConfirm(
      `${walletTrimmed} — это верный кошелек? Отменить действие будет не возможно!`,
      (ok) => {
        if (ok) {
          setSending(true);
          Api.post('/invite/payout', {
            wallet: walletTrimmed,
          })
            .then(() => {
              goBack();
              dispatch(toastActions.setToastSuccess());
            })
            .catch((e) => {
              dispatch(toastActions.setToastFail(e.message));
            })
            .finally(() => {
              setSending(false);
            });
        }
      },
    );
  };

  return (
    <>
      <FormLayout>
        <FormItem
          top="Адрес кошелька в сети TON"
          bottom="Все доступные TON будут отправлены на этот кошелек, отменить действие будет не возможно."
        >
          <Input
            value={wallet}
            onChange={(e) => setWallet(e.target.value)}
            placeholder="Введите адрес кошелька..."
          />
        </FormItem>
      </FormLayout>
      <MainButton progress={sending} onClick={handleSubmit} text="Вывести" />
    </>
  );
};
