import { all, put, takeLatest, call, delay, takeEvery, select } from 'redux-saga/effects';
import { batchActions } from '$store';
import { toastActions } from '$store/toast';
import { Api } from '$api';
import { mainActions, mainSelector } from '$store/main';
import { cardsActions, cardsSelector } from '$store/cards/index';
import { CardsSwipeAction } from '$store/cards/interface';
import router from '$navigation/router';
import { EDIT, LIKES_LIMIT, MATCH, PREMIUM } from '$shared/constants/pages';
import { statReachGoal } from '$utils';
import bridge, { EAdsFormats } from '@vkontakte/vk-bridge';

function* loadCardsWorker() {
  try {
    const { cards } = yield select(cardsSelector);
    const data = yield call(Api.post, '/cards', {
      skipIds: cards,
      sharedId: window.sharedProfileId,
    });
    const actions: any = [
      cardsActions.setCards(data.cards),
      cardsActions.setLoadingStatus('loaded'),
      mainActions.setUsers(data.users),
    ];
    if (data.ad) {
      actions.push(cardsActions.setAd(data.ad));
    }

    yield put(batchActions(...actions));
    window.sharedProfileId = 0;
  } catch (e) {
    yield put(
      batchActions(
        cardsActions.setLoadingStatus('failed'),
        toastActions.setToastFail(e.message),
      ),
    );
  }
}

let swipeCounter = 0;
function* swipeWorker(action: CardsSwipeAction) {
  try {
    const data = yield call(Api.post, '/cards/swipe', {
      userId: action.payload.userId,
      reason: action.payload.reason,
    });
    if (data.isMatch) {
      router.navigate(MATCH, { id: action.payload.userId });
    }

    const { cards } = yield select(cardsSelector);
    if (cards.length < 4) {
      yield put(cardsActions.loadCards());
    }

    if (action.payload.reason === 2) {
      yield call(statReachGoal, 'swipe_like');
    } else {
      yield call(statReachGoal, 'swipe_dislike');
    }

    swipeCounter++;

    const { premiumRequested } = yield select(mainSelector);
    if (swipeCounter >= 5 && !premiumRequested && !data.isMatch) {
      yield call(router.navigate, PREMIUM);
    } else if (
      window.isVK &&
      swipeCounter > 0 &&
      swipeCounter % 10 === 0 &&
      !window.hasPremium
    ) {
      bridge.send('VKWebAppShowNativeAds', { ad_format: EAdsFormats.INTERSTITIAL });
    }
  } catch (e) {
    if (e && e.code === 'need_create_account') {
      router.navigate(EDIT, {
        need_reg: 1,
      });
    } else if (e && e.code === 'likes_limit') {
      router.navigate(LIKES_LIMIT);
    } else {
      yield put(batchActions(toastActions.setToastFail(e.message)));
    }
  }
}

export function* cardsSaga() {
  yield all([
    takeLatest(cardsActions.loadCards, loadCardsWorker),
    takeEvery(cardsActions.swipe, swipeWorker),
  ]);
}
