import s from './History.module.sass';

import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '$store/rootReducer';
import { activityActions, chatHistorySelector, dialogSelector } from '$store/activity';
import { MessageModel } from '$store/models';
import { classNames, format, formatDate } from '$utils';
import { Linkify, LottieIcon } from '$shared/components';
import { useTranslator } from '$hooks';

const Message: FC<{ message: MessageModel }> = (props) => {
  const { message } = props;

  let cont: any = null;
  if (message.attachType === 'like') {
    cont = <LottieIcon loop name="heart" autoplay size={82} className={s.heart} />;
  } else {
    cont = (
      <div className={s.messageBubble}>
        <div className={s.messageText}>
          <Linkify>{message.text}</Linkify>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames({
        [s.message]: true,
        [s.outbox]: message.peerId !== message.fromId,
      })}
    >
      {cont}
    </div>
  );
};

export const History: FC<{ peerId: number }> = (props) => {
  const { peerId } = props;
  const t = useTranslator();
  const dialog = useSelector((state: RootState) => dialogSelector(state, peerId));
  const dispatch = useDispatch();

  useEffect(() => {
    if (dialog?.unread > 0) {
      dispatch(activityActions.readChat(peerId));
    }
  }, [dialog?.unread, peerId]);

  const historyRaw = useSelector((state: RootState) =>
    chatHistorySelector(state, peerId),
  );

  const history = useMemo(() => {
    const flat: MessageModel[] = [];
    for (let id in historyRaw) {
      flat.push(historyRaw[id]);
    }

    flat.sort((a, b) => a.createdAt - b.createdAt);

    let result: any = [];
    let lastDate = '';
    for (let m of flat) {
      const date = format(m.createdAt * 1000, 'd-MMMM-yyyy');
      if (date !== lastDate) {
        result.push({ type: 'date', date: m.createdAt });
        lastDate = date;
      }
      result.push({
        type: 'message',
        message: m,
      });
    }

    return result;
  }, [historyRaw]);

  return (
    <>
      {history.map((item, i) => {
        if (item.type === 'date') {
          return (
            <div key={item.date} className={s.date}>
              {formatDate(new Date(item.date * 1000), t)}
            </div>
          );
        } else if (item.type === 'message') {
          return <Message message={item.message} key={item.message.id} />;
        } else {
          return null;
        }
      })}
    </>
  );
};
