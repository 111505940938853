import { FC } from 'react';
import { Button, Group, Header, Headline, Placeholder } from '@vkontakte/vkui';
import { LottieIcon } from '$shared/components';
import { useRouter } from 'react-router5';
import { INVITE } from '$shared/constants/pages';
import { useTranslator } from '$hooks';

export const InVerification: FC = () => {
  const t = useTranslator();
  const router = useRouter();

  return (
    <>
      <Group>
        <Placeholder
          stretched
          icon={<LottieIcon name="checking" loop size={120} />}
          header={t('in_verification_title')}
        >
          {t('in_verification_text')}
        </Placeholder>
      </Group>
      <Group>
        <Placeholder
          icon={<LottieIcon name="duck_money" loop size={84} />}
          action={
            <Button
              size="l"
              onClick={() => {
                router.navigate(INVITE);
              }}
            >
              {t('in_verification_invite_btn')}
            </Button>
          }
          header={
            <Header size="regular" multiline>
              {t('in_verification_invite_title')}
            </Header>
          }
        >
          <Headline level="2">{t('in_verification_invite_text')}</Headline>
        </Placeholder>
      </Group>
    </>
  );
};
