import { all } from 'redux-saga/effects';

import { mainSaga } from '$store/main/sagas';
import { likesSaga } from '$store/likes/sagas';
import { cardsSaga } from '$store/cards/sagas';
import { activitySaga } from '$store/activity/sagas';

export function* rootSaga() {
  yield all([
    mainSaga(),
    likesSaga(),
    cardsSaga(),
    activitySaga(),
  ]);
}
