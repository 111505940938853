import s from './Join.module.sass';
import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  DatePicker,
  FormItem,
  FormLayout,
  Group,
  Input,
  List,
  PanelHeader,
  PanelHeaderBack,
  Placeholder,
  RichCell,
  SegmentedControl,
  Select,
} from '@vkontakte/vkui';
import * as loadImage from 'blueimp-load-image';
import { MainButton, ScrollView } from '$uikit';
import { toastActions } from '$store/toast';
import { useDispatch } from 'react-redux';
import { canvasToBlob, statReachGoal } from '$utils';
import { PhotoModel, VerificationStatusEnum } from '$store/models';
import { Loader, LottieIcon, PhotosEdit } from '$shared/components';
import { Api } from '$api';
import { useCurrentUser, useTranslator } from '$hooks';
import bridge from '@vkontakte/vk-bridge';
import WebApp from '@twa-dev/sdk';
import { Cities } from '$shared/constants/cities';
import { mainActions } from '$store/main';

function ProgressBar({ step, title, caption = null }: any) {
  const progress = (step / 4) * 100;

  return (
    <div className={s.progressBarWrap}>
      <div className={s.progressBar}>
        <div className={s.progressBarIndicator} style={{ width: `${progress}%` }} />
      </div>
      <div className={s.progressBarTitle}>{title}</div>
      {caption && <div className={s.progressBarCaption}>{caption}</div>}
    </div>
  );
}

export const Join: FC = () => {
  const t = useTranslator();
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const [step, setStep] = React.useState(user.joinStep);
  const [sex, setSex] = useState(user.sex || 0);
  const [name, setName] = useState(user.name || window.initUserData?.name || '');
  const [bio, setBio] = useState(user.bio || '');
  const [photos, setPhotos] = useState<PhotoModel[]>(user.photosRich || []);
  const [isSaving, setSaving] = useState(false);
  const [isFetchVKInfo, setFetchVKInfo] = useState(window.isVK);
  const [vkPhoto, setVkPhoto] = useState('');
  const [city, setCity] = useState<number>(user.cityId);
  const [verifyPhoto, setVerifyPhoto] = useState<any>(null);
  const [verifyPhotoUrl, setVerifyPhotoUrl] = useState('');

  const bdayExp = (user.bdate || '').split('-');
  const [bdate, setBdate] = useState(
    bdayExp.length >= 3
      ? {
          day: +bdayExp[2],
          month: +bdayExp[1],
          year: +bdayExp[0],
        }
      : undefined,
  );

  const handleNativeBack = () => {
    setStep((val) => val - 1);
  };

  useEffect(() => {
    if (step > 0) {
      WebApp.BackButton.show();
      WebApp.BackButton.onClick(handleNativeBack);
    } else {
      WebApp.BackButton.hide();
      WebApp.BackButton.offClick(handleNativeBack);
    }
  }, [step]);

  useEffect(() => {
    return () => {
      WebApp.BackButton.hide();
      WebApp.BackButton.offClick(handleNativeBack);
    };
  }, []);

  useEffect(() => {
    statReachGoal('join_page');

    if (window.isVK) {
      bridge
        .send('VKWebAppGetUserInfo')
        .then((data) => {
          if (data.sex > 0 && user.sex === 0) {
            setSex(data.sex);
            setStep(1);
          }

          if (data.first_name && user.name.length === 0) {
            setName(data.first_name);
          }

          if (
            user.photosRich.length === 0 &&
            data.photo_max_orig &&
            data.photo_max_orig.indexOf('camera_') === -1
          ) {
            setVkPhoto(data.photo_max_orig);
          }

          setFetchVKInfo(false);
        })
        .catch(() => {
          setFetchVKInfo(false);
        });
    } else {
      if (WebApp.initDataUnsafe && WebApp.initDataUnsafe.user) {
        if (
          user.photosRich.length === 0 &&
          WebApp.initDataUnsafe.user.photo_url &&
          WebApp.initDataUnsafe.user.photo_url.indexOf('jpeg') > -1
        ) {
          setVkPhoto(WebApp.initDataUnsafe.user.photo_url);
        }

        if (WebApp.initDataUnsafe.user.first_name && user.name.length === 0) {
          setName(WebApp.initDataUnsafe.user.first_name);
        }
      }
    }
  }, []);

  useEffect(() => {
    statReachGoal('join_page_step_' + step);
  }, [step]);

  const handleGeneral = () => {
    const nameParsed = name.trim();
    if (nameParsed.length < 2) {
      dispatch(toastActions.setToastFail(t('join_name_short')));
      return;
    }

    const bioParsed = bio.trim();
    if (bioParsed.length === 0) {
      dispatch(toastActions.setToastFail(t('join_bio_error')));
      return;
    }

    // if (!city) {
    //   dispatch(toastActions.setToastFail('Укажите ваш город.'));
    //   return;
    // }

    if (!bdate || !bdate.year) {
      dispatch(toastActions.setToastFail(t('join_bdate_error')));
      return;
    }

    if (new Date().getFullYear() - bdate.year < 17) {
      dispatch(toastActions.setToastFail(t('join_age_error')));
      return;
    }

    dispatch(toastActions.setToastLoading());
    setSaving(true);
    Api.post('/account/join/general', {
      name: nameParsed,
      bio: bioParsed,
      bDate: `${bdate.year}-${bdate.month}-${bdate.day}`,
      sex,
      cityId: city,
    })
      .then(() => {
        dispatch(toastActions.hideToast());
        setStep(step + 1);
      })
      .catch((e) => {
        dispatch(toastActions.setToastFail(e.message));
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handlePhotos = () => {
    if (!photos.length) {
      dispatch(toastActions.setToastFail(t('join_photos_empty')));
      return;
    }

    setSaving(true);
    dispatch(toastActions.setToastLoading());
    Api.post<any, any>('/account/join/photos', {
      photos: photos.map((p) => p.id),
    })
      .then((data) => {
        dispatch(toastActions.hideToast());
        setStep(step + 1);
      })
      .catch((err) => {
        dispatch(toastActions.setToastFail(err.message));
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleFile = (recapture: boolean = false) => {
    const old = document.getElementById('capture_verify');
    if (old) {
      document.body.removeChild(old);
    }

    const f = document.createElement('input');
    f.id = 'capture_verify';
    f.style.display = 'none';
    f.type = 'file';
    f.name = 'file';
    f.accept = 'image/*';
    f.capture = 'camera';
    f.onchange = (e) => {
      // @ts-ignore
      if (!e.target.files.length) {
        return;
      }

      // @ts-ignore
      const file = e.target.files[0];

      loadImage(file, {
        maxWidth: 800,
        maxHeight: 800,
        meta: true,
        canvas: true,
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
        orientation: true,
      })
        .then((data) => {
          const quality = 0.8;
          canvasToBlob(data.image, quality)
            .then((blob) => {
              setVerifyPhoto(blob);
              document.body.removeChild(f);

              if (!recapture) {
                setStep(step + 1);
              }

              setVerifyPhotoUrl(data.image.toDataURL());
            })
            .catch((err) => {
              dispatch(toastActions.setToastFail(err.message));
            });
        })
        .catch((e) => {
          dispatch(toastActions.setToastFail(e.message));
        });

      // const reader = new FileReader();
      // reader.onload = function (e) {
      //   // @ts-ignore
      //   setVerifyPhotoUrl(e.target.result);
      // };
      // reader.readAsDataURL(file);
    };
    document.body.appendChild(f);
    f.click();
  };

  const handleSubmit = () => {
    setSaving(true);
    dispatch(toastActions.setToastLoading());

    const form = new FormData();
    form.append('file', verifyPhoto);
    Api.post('/account/join/submit', form)
      .then(() => {
        dispatch(toastActions.hideToast());
        dispatch(
          mainActions.setVerificationStatus(VerificationStatusEnum.InVerification),
        );
      })
      .catch((e) => {
        dispatch(toastActions.setToastFail(e.message));
      })
      .finally(() => {
        setSaving(false);
      });
  };

  function renderStep() {
    if (isFetchVKInfo) {
      return <Loader isWrapped />;
    }

    if (step === 0) {
      return (
        <>
          <FormLayout>
            <FormItem top={t('join_general_title')}>
              <Input
                autoFocus
                placeholder={t('join_general_name')}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormItem>
            <FormItem bottom={t('join_general_bio_bottom')}>
              <Input
                autoFocus
                placeholder={t('join_general_bio')}
                value={bio}
                onChange={(e) => setBio(e.target.value)}
              />
            </FormItem>
            <FormItem top={t('join_general_sex')}>
              <SegmentedControl
                defaultValue={sex}
                onChange={(newVal) => {
                  setSex(newVal ? +newVal : 0);
                }}
                options={[
                  {
                    label: t('join_general_sex_male'),
                    value: 2,
                  },
                  {
                    label: t('join_general_sex_female'),
                    value: 1,
                  },
                ]}
              />
            </FormItem>
            <FormItem top={t('join_general_bdate')}>
              <DatePicker
                min={{
                  day: 1,
                  month: 1,
                  year: 1974,
                }}
                max={{
                  day: 1,
                  month: 1,
                  year: 2006,
                }}
                defaultValue={bdate}
                onDateChange={(newDate) => {
                  setBdate(newDate);
                }}
              />
            </FormItem>
            <FormItem top={t('join_general_city')} bottom={t('join_general_city_bottom')}>
              <Select
                searchable
                defaultValue={city}
                onChange={(newVal) => {
                  setCity(+newVal.target.value);
                }}
                options={Cities}
              />
            </FormItem>
            {window.isVK ? (
              <FormItem>
                <Button onClick={handleGeneral} size="l" stretched>
                  {t('join_continue')}
                </Button>
              </FormItem>
            ) : (
              <MainButton onClick={handleGeneral} text={t('join_continue')} />
            )}
          </FormLayout>
        </>
      );
    } else if (step === 1) {
      return (
        <>
          <FormLayout>
            <FormItem top={t('join_photos_title')} bottom={t('join_photos_bottom')}>
              <PhotosEdit photos={photos} setPhotos={setPhotos} prefetchPhoto={vkPhoto} />
            </FormItem>
            <MainButton
              onClick={handlePhotos}
              text={t('join_continue')}
              progress={isSaving}
            />
          </FormLayout>
        </>
      );
    } else if (step === 2) {
      return (
        <>
          <Placeholder
            icon={<LottieIcon name="access" loop size={120} />}
            header={t('join_verify_title')}
          >
            {t('join_verify_text')}
          </Placeholder>
          <Group>
            <List>
              <RichCell caption={t('join_verify_selfie_caption')}>
                {t('join_verify_selfie')}
              </RichCell>
              <RichCell caption={t('join_verify_quality_caption')}>
                {t('join_verify_quality')}
              </RichCell>
            </List>
          </Group>
          <MainButton
            onClick={() => setStep((prev) => prev + 1)}
            text={t('join_continue')}
            progress={isSaving}
          />
        </>
      );
    } else if (step === 3) {
      return (
        <>
          <div className={s.verifyExample}>
            <img className={s.verifyExampleImg} src="/assets/verify.jpg" />
            <div className={s.verifyExampleLabel}>{t('join_verify2_info')}</div>
          </div>
          <MainButton
            onClick={() => handleFile()}
            text={t('join_verify2_btn')}
            progress={isSaving}
          />
        </>
      );
    } else if (step === 4) {
      return (
        <>
          <div className={s.verifyExampleCheckWrap}>
            <div className={s.verifyExampleCheck}>
              <img
                className={s.verifyExampleCheckImg}
                style={{ backgroundImage: `url(${verifyPhotoUrl})` }}
              />
              <img
                className={s.verifyExampleCheckImg}
                style={{ backgroundImage: `url(/assets/verify.jpg)` }}
              />
            </div>
            <div className={s.verifyExampleLabel}>{t('join_verify_confirm_info')}</div>
            <div className={s.verifyExampleCheckRecapture}>
              <Button size="l" mode="secondary" onClick={() => handleFile(true)}>
                {t('join_verify_confirm_recapture')}
              </Button>
            </div>
          </div>
          <MainButton
            onClick={handleSubmit}
            text={t('join_verify_confirm_submit')}
            progress={isSaving}
          />
        </>
      );
    }
  }

  return (
    <div className={s.wrap}>
      {window.isVK && step > 0 && (
        <PanelHeader
          before={
            <PanelHeaderBack
              onClick={() => {
                setStep((prev) => prev - 1);
              }}
            />
          }
        />
      )}
      <ScrollView>{renderStep()}</ScrollView>
    </div>
  );
};
