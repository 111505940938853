import { all, put, takeLatest, call, delay, takeEvery, select } from 'redux-saga/effects';

import { batchActions } from '$store';
import { Api } from '$api';
import { mainActions } from '$store/main';
import { activityActions, dialogSelector } from '$store/activity/index';
import {
  DeleteMatchAction,
  LoadChatAction,
  ReadChatAction,
  RefreshChatAction,
  SendLikeAction,
  SendTextAction,
} from '$store/activity/interface';
import { toastActions } from '$store/toast';
import { scrollToBottom } from '$utils';
import { RootState } from '$store/rootReducer';
import { goBack } from '$navigation/router';

function* loadWorker() {
  try {
    const data = yield call(Api.get, '/activity');
    yield put(
      batchActions(
        activityActions.setMatches(data.matches),
        activityActions.setMessages(data.messages),
        activityActions.setDialogs(data.dialogs),
        mainActions.setUsers(data.users),
      ),
    );
  } catch (e) {
    yield delay(3000);
    yield put(activityActions.load());
  }
}

function* refreshActivityWorker() {
  try {
    const data = yield call(Api.get, '/activity');
    yield put(
      batchActions(
        activityActions.setMatches(data.matches),
        activityActions.setMessages(data.messages),
        activityActions.setDialogs(data.dialogs),
        mainActions.setUsers(data.users),
        activityActions.incrementActivityRefreshIndex(),
      ),
    );
    console.log('refreshed');
  } catch (e) {
    yield put(activityActions.incrementActivityRefreshIndex());
  }
}

function* loadChatWorker(action: LoadChatAction) {
  try {
    const peerId = action.payload;
    const data = yield call(Api.get, `/activity/chat/${peerId}`);
    const actions: any = [
      activityActions.setMessages(data.messages),
      activityActions.setChatLoadingStatus('loaded'),
      mainActions.setUsers(data.users),
      activityActions.setHasMatch(data.hasMatch),
    ];
    if (data.dialog) {
      actions.push(activityActions.setDialogs([data.dialog]));
    }
    yield put(batchActions(...actions));
    yield delay(1);
    yield call(scrollToBottom);
  } catch (e) {
    yield put(
      batchActions(
        toastActions.setToastFail(e.message),
        activityActions.setChatLoadingStatus('failed'),
      ),
    );
  }
}

function* refreshChatWorker(action: RefreshChatAction) {
  try {
    const dialog = yield select((state: RootState) =>
      dialogSelector(state, action.payload),
    );
    const peerId = action.payload;
    const data = yield call(Api.get, `/activity/chat/${peerId}`);
    const actions: any = [
      activityActions.setMessages(data.messages),
      activityActions.setChatLoadingStatus('loaded'),
      mainActions.setUsers(data.users),
      activityActions.setHasMatch(data.hasMatch),
      activityActions.incrementRefreshIndex(),
    ];
    if (data.dialog) {
      actions.push(activityActions.setDialogs([data.dialog]));
    }
    yield put(batchActions(...actions));

    const hasUpdates =
      (!dialog && data.dialog) ||
      (dialog && data.dialog && dialog.lastId !== data.dialog.lastId);

    if (hasUpdates) {
      yield put(batchActions(...actions));
      yield delay(1);
      yield call(scrollToBottom);
    }
  } catch (e) {
    yield put(activityActions.incrementRefreshIndex());
  }
}

function* sendLikeWorker(action: SendLikeAction) {
  try {
    yield put(toastActions.setToastLoading());
    const peerId = action.payload;
    const data = yield call(Api.post, `/activity/chat/${peerId}/like/send`);
    yield put(
      batchActions(
        toastActions.hideToast(),
        activityActions.setMessages([data.message]),
        activityActions.setDialogs([data.dialog]),
      ),
    );
    yield call(scrollToBottom);
    yield delay(500);
    yield call(scrollToBottom);
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* sendTextWorker(action: SendTextAction) {
  try {
    const { peerId, text, onDone } = action.payload;

    if (text.length === 0) {
      yield put(toastActions.setToastFail('Сообщение не может быть пустым'));
      return;
    }

    yield put(toastActions.setToastLoading());
    const data = yield call(Api.post, `/activity/chat/${peerId}/send`, {
      text,
    });
    yield put(
      batchActions(
        toastActions.hideToast(),
        activityActions.setMessages([data.message]),
        activityActions.setDialogs([data.dialog]),
      ),
    );
    onDone();
    yield call(scrollToBottom);
    yield delay(500);
    yield call(scrollToBottom);
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

function* readChatWorker(action: ReadChatAction) {
  try {
    const peerId = action.payload;
    const data = yield call(Api.post, `/activity/chat/${peerId}/read`);
    yield put(activityActions.setDialogs([data.dialog]));
  } catch (e) {}
}

function* deleteMatchWorker(action: DeleteMatchAction) {
  try {
    const peerId = action.payload;
    yield put(toastActions.setToastLoading());
    yield call(Api.post, `/activity/chat/${peerId}/delete`);
    yield put(
      batchActions(toastActions.hideToast(), activityActions.matchDeleted(peerId)),
    );
    yield call(goBack);
  } catch (e) {
    yield put(toastActions.setToastFail(e.message));
  }
}

export function* activitySaga() {
  yield all([
    takeLatest(activityActions.load, loadWorker),
    takeEvery(activityActions.loadChat, loadChatWorker),
    takeEvery(activityActions.sendLike, sendLikeWorker),
    takeEvery(activityActions.sendText, sendTextWorker),
    takeEvery(activityActions.readChat, readChatWorker),
    takeLatest(activityActions.refreshChat, refreshChatWorker),
    takeLatest(activityActions.refreshActivity, refreshActivityWorker),
    takeLatest(activityActions.deleteMatch, deleteMatchWorker),
  ]);
}
