import s from './ScrollView.module.sass';

import { FC } from 'react';

export const ScrollView: FC = ({ children }) => {
  return (
    <div className={s.wrap} id="ScrollView">
      {children}
    </div>
  );
};
