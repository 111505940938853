import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ActivityState,
  DeleteMatchAction,
  DialogsMap,
  LoadChatAction,
  LoadingStatus,
  MessagesMap,
  ReadChatAction,
  RefreshChatAction,
  SendLikeAction,
  SendTextAction,
  SetMatchesAction,
} from './interface';
import { RootState } from 'store/rootReducer';
import { DialogModel, MessageModel } from '$store/models';
import { mainSelector } from '$store/main';

const initialState: ActivityState = {
  matches: [],
  loadingStatus: 'loading',
  dialogs: {},
  history: {},
  chatLoadingStatus: 'loading',
  hasMatch: false,
  chatRefreshIndex: 0,
  activityRefreshIndex: 0,
};

export const { actions, reducer } = createSlice({
  name: 'matches',
  initialState,
  reducers: {
    load(state) {
      state.loadingStatus = 'loading';
    },
    setMatches(state, action: SetMatchesAction) {
      state.loadingStatus = 'loaded';
      state.matches = action.payload;
    },
    setDialogs(state, action: PayloadAction<DialogModel[]>) {
      const copy = { ...state.dialogs };
      for (let d of action.payload) {
        copy[d.peerId] = d;
      }
      state.dialogs = copy;
    },
    setMessages(state, action: PayloadAction<MessageModel[]>) {
      const copy = { ...state.history };
      for (let m of action.payload) {
        if (!copy[m.peerId]) {
          copy[m.peerId] = {};
        }
        copy[m.peerId][m.id] = m;
      }
      state.history = copy;
    },
    setFailed(state) {
      state.loadingStatus = 'failed';
      state.matches = [];
    },
    loadChat(state, action: LoadChatAction) {
      state.chatLoadingStatus = 'loading';
    },
    setChatLoadingStatus(state, action: PayloadAction<LoadingStatus>) {
      state.chatLoadingStatus = action.payload;
    },
    setHasMatch(state, action: PayloadAction<boolean>) {
      state.hasMatch = action.payload;
    },
    sendLike(state, action: SendLikeAction) {},
    sendText(state, action: SendTextAction) {},
    readChat(_, __: ReadChatAction) {},
    refreshChat(_, __: RefreshChatAction) {},
    incrementRefreshIndex(state) {
      state.chatRefreshIndex++;
    },
    refreshActivity() {},
    incrementActivityRefreshIndex(state) {
      state.activityRefreshIndex++;
    },
    deleteMatch(_, __: DeleteMatchAction) {},
    matchDeleted(state, action: DeleteMatchAction) {
      delete state.dialogs[action.payload];
      delete state.history[action.payload];

      const copy = [...state.matches];
      for (let i = 0; i < copy.length; i++) {
        if (copy[i].fromId === action.payload) {
          copy.splice(i, 1);
          break;
        }
      }
      state.matches = copy;
    },
  },
});

export { reducer as activityReducer, actions as activityActions };

export const activitySelector = (state: RootState) => state.activity;
export const chatLoadingStatusSelector = (state: RootState) =>
  state.activity.chatLoadingStatus;

export const hasMatchSelector = (state: RootState) => state.activity.hasMatch;

export const dialogsSelector = (state: RootState) => state.activity.dialogs;

export const historySelector = (state: RootState) => state.activity.history;

export const chatRefreshIndexSelector = (state: RootState) =>
  state.activity.chatRefreshIndex;

export const activityRefreshIndexSelector = (state: RootState) =>
  state.activity.activityRefreshIndex;

export const chatHistorySelector = createSelector(
  activitySelector,
  (_, peerId: number) => peerId,
  (activity, peerId) => {
    return activity.history[peerId] || {};
  },
);

export const dialogSelector = createSelector(
  activitySelector,
  (_, dialogId: number) => dialogId,
  (activity, dialogId) => {
    return activity.dialogs[dialogId] || {};
  },
);
