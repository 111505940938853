import { useSelector } from 'react-redux';

import { UserModel } from 'store/models';
import { RootState } from 'store/rootReducer';
import { userIdSelector, userSelector } from '$store/main';

export const useUser = (userId: number): UserModel => {
  const userInfo = useSelector((state: RootState) => userSelector(state, userId));
  return userInfo as UserModel;
};

export const useCurrentUser = (): UserModel => {
  const userId = useSelector(userIdSelector);
  const userInfo = useSelector((state: RootState) => userSelector(state, userId));
  return userInfo as UserModel;
}
