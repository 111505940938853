import createRouter from 'router5';
import browserPlugin from 'router5-plugin-browser';
import listenersPlugin from 'router5-plugin-listeners';

import {
  EDIT,
  FILTERS,
  LIKES,
  PROFILE_CARD,
  SETTINGS,
  DEBUG,
  HOME,
  OTHER,
  ACTIVITY,
  CARDS,
  MATCH,
  CHAT,
  JOIN,
  PREMIUM,
  PREMIUM_INFO,
  LIKES_LIMIT,
  PAYWALL,
  INVITE,
  FRIENDS,
  MODER,
  IN_VERIFICATION,
  MODER_VERIFY,
  INVITE_PAYOUT,
} from 'shared/constants/pages';

const routes = [
  { name: HOME, path: '/' },
  { name: EDIT, path: '/edit' },
  { name: JOIN, path: '/join' },
  { name: IN_VERIFICATION, path: '/in_verification' },
  // { name: LIKES, path: '/likes' },
  { name: SETTINGS, path: '/preferences' },
  { name: PROFILE_CARD, path: '/profile/:id' },
  { name: MATCH, path: '/match/:id' },
  { name: CHAT, path: '/chat/:peerId' },
  { name: FILTERS, path: '/filters' },
  { name: DEBUG, path: '/debug' },
  { name: PREMIUM, path: '/premium' },
  { name: PREMIUM_INFO, path: '/premium/info' },
  { name: LIKES_LIMIT, path: '/likes_limit' },
  { name: PAYWALL, path: '/paywall' },
  { name: INVITE, path: '/invite' },
  { name: FRIENDS, path: '/friends' },
  { name: MODER, path: '/moder' },
  { name: MODER_VERIFY, path: '/moder/verify' },
  { name: INVITE_PAYOUT, path: '/invite/payout' },
  // { name: OTHER, path: '/other' },
  // { name: ACTIVITY, path: '/activity' },
  // { name: CARDS, path: '/cards' },
];

const router = createRouter(routes, {
  allowNotFound: false,
  autoCleanUp: false,
  defaultRoute: HOME,
  defaultParams: {},
  queryParamsMode: 'loose',
  trailingSlashMode: 'default',
  strictTrailingSlash: false,
  caseSensitive: false,
  urlParamsEncoding: 'default',
});

const urlParams = new URLSearchParams(window.location.search);
router.usePlugin(
  browserPlugin({
    useHash:
      !!window.location.search.match(/vk_user_id/) &&
      urlParams.get('vk_platform') !== 'mobile_android',
  }),
);
router.usePlugin(listenersPlugin());

router.useMiddleware((router) => (toState, fromState, done) => {
  const transition = window.history?.state?.meta?.options?.transition;
  if (!fromState && transition && toState.meta) {
    toState.meta.options = {
      transition,
    };
  }
  if (fromState && toState) {
    if (toState.meta?.options) {
      const toTransition = toState.meta?.options?.transition;
      const replace = toState.meta?.options?.replace;

      if (replace) {
        if (toTransition === false) {
          // @ts-ignore
          toState.meta.options = fromState.meta?.options;
        } else {
          toState.meta.options.transition = fromState.meta?.options?.transition;
        }
      } else {
        toState.meta.options.transition = toTransition || toTransition === undefined;
      }
    }
  }
  done();
});

export const hasHistoryBack = (): boolean => {
  const { meta } = history.state;
  return !!meta?.options?.transition;
};

export const goBack = () => {
  window.history.back();
};

const nativeGoBack = () => {
  const curRoute = router.getState();
  console.log(curRoute);
  window.history.back();
};

// @ts-ignore
router.addListener((to, from) => {
  const { WebApp } = Telegram;

  let isMain = false;
  if (to && to.name === HOME) {
    isMain = true;
  }

  if (hasHistoryBack() && !isMain) {
    WebApp.BackButton.onClick(nativeGoBack);
    WebApp.BackButton.show();
  } else {
    WebApp.BackButton.offClick(nativeGoBack);
    WebApp.BackButton.hide();
  }

  WebApp.setHeaderColor('secondary_bg_color');
  WebApp.setBackgroundColor('secondary_bg_color');
});

export default router;
