import { FC } from 'react';
import { Placeholder } from '@vkontakte/vkui';
import { LottieIcon } from '$shared/components';
import { useTranslator } from '$hooks';

export const Declined: FC = () => {
  const t = useTranslator();

  return (
    <>
      <Placeholder
        stretched
        icon={<LottieIcon name="blocked" loop size={120} />}
        header={t('declined_title')}
      >
        {t('declined_text')}
      </Placeholder>
    </>
  );
};
