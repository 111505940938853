import { PayloadAction } from '@reduxjs/toolkit';

export enum ToastActivity {
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

export type SetToastAction = PayloadAction<string | undefined>;

export interface ToastState {
  label: string;
  activity: ToastActivity | null;
}
