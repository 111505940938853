import s from './Other.module.sass';

import React, { FC, useCallback, useState } from 'react';
import { useRouter } from 'react-router5';

import { useCurrentUser, useTranslator } from '$hooks';
import {
  EDIT,
  FILTERS,
  FRIENDS,
  INVITE,
  MODER,
  MODER_VERIFY,
  PREMIUM_INFO,
  PROFILE_CARD,
  SETTINGS,
} from '$shared/constants/pages';
import { CellButton, CellButtonGroup } from '$uikit';
import { IcBalance28, IcEdit28, IcFilters28, IcSettings28, IcStar28 } from '$assets';
import { Button, PanelHeader } from '@vkontakte/vkui';
import { useSelector } from 'react-redux';
import { mainSelector } from '$store/main';

export const Other: FC = () => {
  const t = useTranslator();
  const router = useRouter();
  const user = useCurrentUser();
  const { hasPremium, isModer } = useSelector(mainSelector);
  const [geo, setGeo] = useState('none');

  const handleProfile = useCallback(() => {
    router.navigate(PROFILE_CARD, { id: user.id, skipActions: true });
  }, [user]);

  function geoFindMe() {
    function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      setGeo(`Latitude: ${latitude} °, Longitude: ${longitude} °`);
    }

    function error() {
      setGeo('Unable to retrieve your location');
    }

    if (!navigator.geolocation) {
      setGeo('Geolocation is not supported by your browser');
    } else {
      setGeo('Locating…');
      navigator.geolocation.getCurrentPosition(success, error);
    }
  }

  return (
    <div>
      {window.isVK && <PanelHeader>{t('other_title')}</PanelHeader>}
      <div className={s.userInfo}>
        <div
          className={s.userAvatar}
          style={{ backgroundImage: `url(${user?.photos[0]})` }}
          onClick={handleProfile}
        />
        <div className={s.userName} onClick={handleProfile}>
          {user?.name}
          {user?.age > 0 ? `, ${user.age}` : ''}
        </div>
      </div>
      <CellButtonGroup>
        <CellButton icon={IcEdit28} onClick={() => router.navigate(EDIT)}>
          {t('other_edit')}
        </CellButton>
        <CellButton icon={IcFilters28} onClick={() => router.navigate(FILTERS)}>
          {t('other_filters')}
        </CellButton>
        <CellButton icon={IcBalance28} onClick={() => router.navigate(INVITE)}>
          Заработайте на друзьях
        </CellButton>
        {hasPremium && (
          <CellButton icon={IcStar28} onClick={() => router.navigate(PREMIUM_INFO)}>
            {t('other_premium')}
          </CellButton>
        )}
        <CellButton icon={IcSettings28} onClick={() => router.navigate(SETTINGS)}>
          {t('other_settings')}
        </CellButton>
        {/*{!window.isVK && (*/}
        {/*  <CellButton icon={IcBalance28} onClick={() => router.navigate(FRIENDS)}>*/}
        {/*    Бесплатная подписка*/}
        {/*  </CellButton>*/}
        {/*)}*/}
        {isModer && (
          <CellButton onClick={() => router.navigate(MODER_VERIFY)}>Moder</CellButton>
        )}
      </CellButtonGroup>
    </div>
  );
};
