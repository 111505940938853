import { all, put, takeLatest, call, delay } from 'redux-saga/effects';
import { likesActions } from '$store/likes/index';
import { batchActions } from '$store';
import { toastActions } from '$store/toast';
import { Api } from '$api';
import { mainActions } from '$store/main';

function* loadLikesWorker() {
  try {
    const data = yield call(Api.get, '/likes');
    yield put(
      batchActions(
        likesActions.setLikes(data.likes),
        mainActions.setUsers(data.users),
      ),
    );
  } catch (e) {
    yield put(
      batchActions(
        likesActions.setLikesFailed(),
        toastActions.setToastFail(e.message),
      ),
    );
  }
}

export function* likesSaga() {
  yield all([
    takeLatest(likesActions.loadLikes, loadLikesWorker),
  ]);
}
