import s from './Friends.module.sass';

import React, { FC, useEffect, useState } from 'react';
import {
  Banner,
  Button,
  Group,
  PanelHeader,
  PanelHeaderBack,
  Placeholder,
} from '@vkontakte/vkui';
import { goBack } from '$navigation/router';
import { Loader, LottieIcon } from '$shared/components';
import { useCurrentUser, useTranslator } from '$hooks';
import { useDispatch } from 'react-redux';
import { toastActions } from '$store/toast';
import copyTextToClipboard from 'copy-text-to-clipboard';
import { CellButton, CellButtonGroup, InlineHeader, ScrollView } from '$uikit';
import { Api } from '$api';
import { mainActions } from '$store/main';
import { statReachGoal } from '$utils';
import { Icon56ErrorOutline } from '@vkontakte/icons';
import WebApp from '@twa-dev/sdk';

export const Friends: FC = () => {
  const user = useCurrentUser();
  const link = `https://t.me/nzlchatbot?start=ref_${user?.id ?? 0}`;
  const t = useTranslator();
  const dispatch = useDispatch();
  const [loadingStatus, setLoadingStatus] = useState('loading');
  const [data, setData] = useState({
    balance: 0,
    reward: 0,
  });

  const loadData = () => {
    setLoadingStatus('loading');
    Api.get<any, any>('/invite')
      .then((resp) => {
        setData(resp);
        setLoadingStatus('');
      })
      .catch((e) => {
        setLoadingStatus('failed');
        dispatch(toastActions.setToastFail(e.message));
      });
  };

  useEffect(() => {
    statReachGoal('invite_open');
    loadData();
  }, []);

  const handleCopy = () => {
    copyTextToClipboard(link);
    dispatch(toastActions.setToastSuccess('Скопированно!'));
  };

  const handlePremium = () => {
    dispatch(toastActions.setToastLoading());
    Api.post('/invite/premium/activate')
      .then(() => {
        dispatch(toastActions.setToastSuccess());
        dispatch(mainActions.setHasPremium(true));
      })
      .catch((e) => {
        dispatch(toastActions.setToastFail(e.message));
      });
  };

  function renderContent() {
    if (loadingStatus === 'loading') {
      return <Loader isWrapped />;
    }

    if (loadingStatus === 'failed') {
      return (
        <Placeholder
          icon={<Icon56ErrorOutline />}
          stretched
          header={t('error_title')}
          action={<Button onClick={loadData}>{t('error_button')}</Button>}
        >
          {t('error_text')}
        </Placeholder>
      );
    }

    return (
      <>
        {/*<Group>*/}
        {/*  <Banner*/}
        {/*    before={<div className={s.bannerImage} />}*/}
        {/*    header="50 рублей за друга!"*/}
        {/*    text="Приглашайте друзей и зарабатывайте деньги через бота."*/}
        {/*    actions={*/}
        {/*      window.isVK ? (*/}
        {/*        <Button size="m" href="https://t.me/TasksHeroBot" target="_blank">*/}
        {/*          Начать зарабатывать*/}
        {/*        </Button>*/}
        {/*      ) : (*/}
        {/*        <Button*/}
        {/*          size="m"*/}
        {/*          onClick={() => WebApp.openTelegramLink('https://t.me/TasksHeroBot')}*/}
        {/*        >*/}
        {/*          Начать зарабатывать*/}
        {/*        </Button>*/}
        {/*      )*/}
        {/*    }*/}
        {/*  />*/}
        {/*</Group>*/}
        <Group>
          <Placeholder
            icon={<LottieIcon loop name="paywall" />}
            header="Пригласите 3 друзей и получите подписку!"
            action={<Button onClick={handleCopy}>Копировать ссылку</Button>}
          >
            Отправьте эту ссылку своим друзьям, после регистрации по которой вы получите
            монеты.
            <div className={s.inviteLink} onClick={handleCopy}>
              {link.replace('https://', '')}
            </div>
          </Placeholder>
        </Group>
        <Group>
          <div className={s.balanceWrap}>
            <div className={s.balance}>
              <div className={s.balanceLabel}>Ваш баланс:</div>
              <div className={s.balanceAmount}>{data.balance} монет</div>
            </div>
          </div>
          <InlineHeader>Потратить монеты</InlineHeader>
          <CellButtonGroup style={{ paddingTop: 0 }}>
            <CellButton indicator="3 монеты" onClick={handlePremium}>
              Премиум
            </CellButton>
          </CellButtonGroup>
        </Group>
      </>
    );
  }

  return (
    <>
      {window.isVK && (
        <PanelHeader before={<PanelHeaderBack onClick={() => goBack()} />} />
      )}
      <ScrollView>{renderContent()}</ScrollView>
    </>
  );
};
