import axios from 'axios';

import { getLanguage } from '$translation';
import { getAuthorization, getCaptchaToken } from '$utils';

export const Api = axios.create({
  baseURL: 'https://telegramapi.nuzzle.dating',
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    // 'Cache-Control': 'no-cache',
    'Accept-Language': getLanguage(),
  },
});

Api.interceptors.request.use(
  (config) => {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: getAuthorization(),
        Version: 3,
        Timezone: new Date().getTimezoneOffset(),
      },
    };
  },
  (error) => {
    console.warn('error', error);
    return Promise.reject(error);
  },
);

let version = 0;

Api.interceptors.response.use(
  (response) => {
    if (['document', 'text'].indexOf(response.config.responseType as string) > -1) {
      return response.data;
    }

    if (response.headers.version) {
      const newVersion = parseFloat(response.headers.version);
      if (version && newVersion > version) {
        // @ts-ignore
        location.reload(true);
      }
      version = newVersion;
    }
    return response.status === 200
      ? Promise.resolve(response.data)
      : Promise.reject(response.data);
  },
  async (error) => {
    if (error.response?.status === 428) {
      const action = 'action_' + Date.now();
      const rVersion = error.response.headers['g-recaptcha-version'];
      let token = '';

      if (rVersion === 'V2') {
        try {
          token = await getCaptchaToken();
        } catch (e) {
          return Promise.reject({
            message: 'Captcha entered incorrectly',
          });
        }
      } else {
        // @ts-ignore
        // eslint-disable-next-line no-undef
        token = await grecaptcha.execute(RECAPTCHA_SITE_KEY, {
          action,
        });
      }

      return Api.request({
        ...error.config,
        headers: {
          ...error.config.headers,
          'g-recaptcha-action': action,
          'g-recaptcha-version': rVersion,
          'g-recaptcha-token': token,
        },
      });
    }

    if (error.message === 'Network Error') {
      return Promise.reject({
        message: 'Ошибка сети',
        isNetworkError: true,
      });
    }
    return Promise.reject(error.response ? error.response.data : error);
  },
);
