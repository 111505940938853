import { combineReducers } from '@reduxjs/toolkit';

import { reducer as toast } from './toast';
import { reducer as main } from './main';
import { reducer as likes } from './likes';
import { reducer as cards } from './cards';
import { reducer as activity } from './activity';

export const rootReducer = combineReducers({
  toast,
  main,
  likes,
  cards,
  activity,
});

export type RootState = ReturnType<typeof rootReducer>;
