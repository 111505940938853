import s from './Chat.module.sass';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRoute, useRouter } from 'react-router5';
import {
  activityActions,
  chatHistorySelector,
  chatLoadingStatusSelector,
  chatRefreshIndexSelector,
  hasMatchSelector,
} from '$store/activity';
import {
  Button,
  PanelHeader,
  PanelHeaderBack,
  Placeholder,
  Spinner,
} from '@vkontakte/vkui';
import { Icon56ErrorOutline } from '@vkontakte/icons';
import { useTranslator, useUser } from '$hooks';
import { IcDelete28, IcLike28, IcSend28, IcUser28 } from '$assets';
import { classNames, scrollToBottom } from '$utils';
import { LottieIcon } from '$shared/components';
import { PROFILE_CARD } from '$shared/constants/pages';
import { RootState } from '$store/rootReducer';
import { History } from '$core/Chat/History/History';
import WebApp from '@twa-dev/sdk';
import { MainButton, ScrollView } from '$uikit';
import { toastActions } from '$store/toast';
import { goBack } from '$navigation/router';

export const Chat: FC = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { route } = useRoute();
  const peerId = +route.params.peerId;
  const loadingStatus = useSelector(chatLoadingStatusSelector);
  const hasMatch = useSelector(hasMatchSelector);
  const t = useTranslator();
  const peer = useUser(peerId);
  const input = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState('');
  const historyRaw = useSelector((state: RootState) =>
    chatHistorySelector(state, peerId),
  );
  const refreshIndex = useSelector(chatRefreshIndexSelector);
  const [isFocused, setFocused] = useState(false);
  const isIphone = WebApp.platform === 'ios' && !window.isVK;

  const loadChat = useCallback(() => {
    dispatch(activityActions.loadChat(peerId));
  }, [peerId]);

  useEffect(() => {
    loadChat();
    scrollToBottom();
  }, [peerId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(activityActions.refreshChat(peerId));
    }, 4000);
    return () => clearTimeout(timer);
  }, [refreshIndex]);

  useEffect(() => {
    if (input.current) {
      input.current.style.height = '0px';
      input.current.style.height = `${input.current.scrollHeight}px`;
    }
  }, [text, input.current, loadingStatus]);

  const handleContact = useCallback(() => {
    if (window.isVK) {
      WebApp.showConfirm(t('chat_share_contact_vk'), (confirmed) => {
        if (confirmed) {
          dispatch(
            activityActions.sendText({
              peerId,
              text: `VK: https://vk.com/id${window.VkID}`,
              onDone: () => {},
            }),
          );
        }
      });
    } else {
      if (!WebApp.initDataUnsafe.user?.username) {
        WebApp.showAlert(t('chat_share_contact_empty'));
      } else {
        WebApp.showConfirm(t('chat_share_contact'), (confirmed) => {
          if (confirmed) {
            dispatch(
              activityActions.sendText({
                peerId,
                text: `Telegram: https://t.me/${WebApp.initDataUnsafe.user?.username}`,
                onDone: () => {},
              }),
            );
          }
        });
      }
    }
  }, [peerId]);

  const history = useMemo(() => {
    return Object.keys(historyRaw);
  }, [historyRaw]);

  const handleProfile = useCallback(() => {
    router.navigate(PROFILE_CARD, { id: peerId, skipActions: true });
  }, [peerId]);

  const handleSend = (e?: any) => {
    if (e) {
      try {
        e.stopPropagation();
        e.preventDefault();
        e.stopImmediatePropagation();
      } catch (e) {}

      try {
        if (e.nativeEvent) {
          e.nativeEvent.stopPropagation();
          e.nativeEvent.preventDefault();
          e.nativeEvent.stopImmediatePropagation();
        }
      } catch (e) {}
    } else {
      if (text.length === 0) {
        dispatch(toastActions.setToastFail(t('chat_send_empty')));
        return;
      }
    }

    if (text.length === 0) {
      dispatch(activityActions.sendLike(peerId));
    } else {
      dispatch(
        activityActions.sendText({
          peerId,
          text: text.trim(),
          onDone: () => {
            setText('');
          },
        }),
      );
    }
  };

  const handleKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 13 && !e.shiftKey && !e.metaKey) {
        return handleSend(e);
      }
    },
    [handleSend],
  );

  const handleDelete = useCallback(() => {
    WebApp.showConfirm(t('chat_delete_confirm'), () => {
      dispatch(activityActions.deleteMatch(peerId));
    });
  }, [peerId]);

  if (loadingStatus === 'loading' && history.length === 0) {
    return (
      <Placeholder stretched>
        <Spinner />
      </Placeholder>
    );
  }

  if (loadingStatus === 'failed') {
    return (
      <Placeholder
        icon={<Icon56ErrorOutline />}
        stretched
        header={t('error_title')}
        action={<Button onClick={loadChat}>{t('error_button')}</Button>}
      >
        {t('error_text')}
      </Placeholder>
    );
  }

  if (!hasMatch && loadingStatus === 'loaded') {
    return (
      <Placeholder
        icon={<LottieIcon loop name="broken_heart" />}
        stretched
        header={t('chat_no_match_title')}
      >
        {t('chat_no_match_text')}
      </Placeholder>
    );
  }

  return (
    <>
      {window.isVK && (
        <PanelHeader before={<PanelHeaderBack onClick={() => goBack()} />}>
          <div className={s.vkHeader}>
            <div
              className={s.headerPhoto}
              style={{ backgroundImage: `url(${peer?.photos[0]})` }}
              onClick={handleProfile}
            />
            <div className={s.headerCont} onClick={handleProfile}>
              <div className={s.headerName}>
                {peer?.name}
                {peer?.age > 0 ? `, ${peer.age}` : ''}
              </div>
            </div>
            <div className={s.headerActions}>
              <div className={s.headerAction} onClick={handleDelete}>
                <IcDelete28 />
              </div>
            </div>
          </div>
        </PanelHeader>
      )}
      {!window.isVK && (!isIphone || !isFocused) && (
        <div className={s.header}>
          <div
            className={s.headerPhoto}
            style={{ backgroundImage: `url(${peer?.photos[0]})` }}
            onClick={handleProfile}
          />
          <div className={s.headerCont} onClick={handleProfile}>
            <div className={s.headerName}>
              {peer?.name}
              {peer?.age > 0 ? `, ${peer.age}` : ''}
            </div>
          </div>
          <div className={s.headerActions}>
            <div className={s.headerAction} onClick={handleDelete}>
              <IcDelete28 />
            </div>
          </div>
        </div>
      )}
      <ScrollView>
        <div className={s.history}>
          {!history.length && (
            <Placeholder
              icon={
                <div
                  className={s.matchPhoto}
                  style={{ backgroundImage: `url(${peer?.photos[0]})` }}
                  onClick={handleProfile}
                />
              }
              stretched
              header={t('chat_match_title', { name: peer?.name })}
            >
              {t('chat_match_text')}
            </Placeholder>
          )}
          {history.length > 0 && <History peerId={peerId} />}
          {peer.danger && (
            <div className={s.danger}>
              Подозрительный пользователь, будьте внимательны!
            </div>
          )}
        </div>
      </ScrollView>
      <div className={s.sendForm}>
        <div className={s.sendFormBtn} onClick={handleContact}>
          <IcUser28 />
        </div>
        <div className={s.sendFormInputWrap}>
          <textarea
            ref={input}
            placeholder={t('chat_placeholder')}
            className={s.sendFormInput}
            onChange={(e) => setText(e.target.value)}
            value={text}
            onKeyDown={handleKeyDown}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
          />
        </div>
        {!isIphone && (
          <div
            className={classNames({
              [s.sendFormBtn]: true,
              [s.sendFormBtnLike]: text.length === 0,
            })}
            onTouchStart={handleSend}
            onMouseDown={handleSend}
          >
            <IcSend28
              className={classNames({
                [s.btnIcon]: true,
                [s.btnIconVisible]: text.length > 0,
              })}
              style={{ zIndex: 2 }}
            />
            <IcLike28
              className={classNames({
                [s.btnIcon]: true,
                [s.btnIconVisible]: text.length === 0,
              })}
              style={{ zIndex: 1 }}
            />
          </div>
        )}
        {isIphone && (
          <div
            className={classNames({
              [s.sendFormBtn]: true,
              [s.sendFormBtnLike]: true,
            })}
            onTouchStart={handleSend}
            onMouseDown={handleSend}
          >
            <IcLike28
              className={classNames({
                [s.btnIcon]: true,
                [s.btnIconVisible]: true,
              })}
            />
          </div>
        )}
        {isIphone && <MainButton text={t('chat_send_message')} onClick={handleSend} />}
      </div>
    </>
  );
};
