import s from './Activity.module.sass';

import { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, PanelHeader, Placeholder, Spinner } from '@vkontakte/vkui';
import { Icon32Cards2Outline } from '@vkontakte/icons';

import {
  activityActions,
  activityRefreshIndexSelector,
  activitySelector,
  dialogsSelector,
  historySelector,
} from '$store/activity';
import { HorizontalScroll, InlineHeader } from '$uikit';
import { LikeItem } from '$core/Likes/LikeItem/LikeItem';
import { useRouter } from 'react-router5';
import { CHAT } from '$shared/constants/pages';
import { DialogModel } from '$store/models';
import { LottieIcon } from '$shared/components';
import { useTranslator, useUser } from '$hooks';

const Dialog: FC<{ dialog: DialogModel }> = (props) => {
  const { dialog } = props;
  const peer = useUser(dialog.peerId);
  const chats = useSelector(historySelector);
  const router = useRouter();
  const t = useTranslator();

  const handleClick = useCallback(() => {
    router.navigate(CHAT, { peerId: dialog.peerId });
  }, [dialog]);

  const text = useMemo(() => {
    if (chats[dialog.peerId] && chats[dialog.peerId][dialog.lastId]) {
      const msg = chats[dialog.peerId][dialog.lastId];

      if (msg.attachType === 'like') {
        return t('dialog_like');
      } else if (msg.attachType === 'photo') {
        return t('dialog_photo');
      }

      return msg.text;
    }

    return '';
  }, [chats, dialog]);

  return (
    <div className={s.dialog} onClick={handleClick}>
      <div
        className={s.dialogPhoto}
        style={{ backgroundImage: `url(${peer?.photos[0]})` }}
      />
      <div className={s.dialogCont}>
        <div className={s.dialogName}>{peer?.name}</div>
        <div className={s.dialogText}>{text}</div>
      </div>
      {dialog.unread > 0 && <div className={s.dialogBadge}>{dialog.unread}</div>}
    </div>
  );
};

export const Activity: FC = () => {
  const dispatch = useDispatch();
  const { loadingStatus, matches } = useSelector(activitySelector);
  const router = useRouter();
  const dialogsRaw = useSelector(dialogsSelector);
  const chats = useSelector(historySelector);
  const refreshIndex = useSelector(activityRefreshIndexSelector);
  const t = useTranslator();

  useEffect(() => {
    dispatch(activityActions.load());
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(activityActions.refreshActivity());
    }, 4000);
    return () => clearTimeout(timer);
  }, [refreshIndex]);

  const handleChat = useCallback((peerId: number) => {
    router.navigate(CHAT, { peerId });
  }, []);

  const dialogs = useMemo(() => {
    const res: DialogModel[] = [];
    for (const key in dialogsRaw) {
      res.push(dialogsRaw[key]);
    }

    res.sort((a, b) => {
      let aTs = a.createdAt;
      if (chats[a.peerId] && chats[a.peerId][a.lastId]) {
        aTs = chats[a.peerId][a.lastId].createdAt;
      }

      let bTs = b.createdAt;
      if (chats[b.peerId] && chats[b.peerId][b.lastId]) {
        bTs = chats[b.peerId][b.lastId].createdAt;
      }

      return bTs - aTs;
    });

    return res;
  }, [dialogsRaw, chats]);

  function renderContent() {
    if (loadingStatus === 'loading' && !matches.length && !dialogs.length) {
      return (
        <Placeholder stretched>
          <Spinner />
        </Placeholder>
      );
    }

    if (!matches.length && !dialogs.length) {
      return (
        <Placeholder
          icon={<Icon32Cards2Outline width={56} height={56} />}
          header={t('activity_empty_title')}
          stretched
        >
          {t('activity_empty_text')}
        </Placeholder>
      );
    }

    return (
      <>
        {matches.length > 0 && (
          <>
            <InlineHeader>{t('activity_matches')}</InlineHeader>
            <HorizontalScroll style={{ marginTop: '-8px' }}>
              {matches.map((like) => (
                <LikeItem
                  key={like.fromId}
                  userId={like.fromId}
                  mini
                  onClick={() => handleChat(like.fromId)}
                />
              ))}
            </HorizontalScroll>
          </>
        )}
        <InlineHeader>{t('activity_chats')}</InlineHeader>
        {!dialogs.length && (
          <Placeholder
            icon={<LottieIcon loop name="chat" />}
            header={t('activity_chats_empty_title')}
            stretched
          >
            {t('activity_chats_empty_text')}
          </Placeholder>
        )}
        {dialogs.length > 0 &&
          dialogs.map((dialog) => <Dialog dialog={dialog} key={dialog.peerId} />)}
      </>
    );
  }

  return (
    <>
      {window.isVK && <PanelHeader>{t('activity_title')}</PanelHeader>}
      {renderContent()}
    </>
  );
};
