import './Toast.sass';

import React, { useCallback, useEffect, useRef } from 'react';
import { Loader } from '$shared/components';
import { useDispatch, useSelector } from 'react-redux';
import { toastActions, ToastActivity, toastSelector } from '../../../store/toast';

export function Toast() {
  const { activity, label } = useSelector(toastSelector);
  const dispatch = useDispatch();
  const timer = useRef<any>(0);

  useEffect(() => {
    clearTimeout(timer.current);

    if (activity !== ToastActivity.LOADING) {
      timer.current = setTimeout(() => {
        dispatch(toastActions.hideToast());
      }, 3000);
    }
  }, [activity]);

  const handleClick = useCallback(() => {
    if (activity !== ToastActivity.LOADING) {
      dispatch(toastActions.hideToast());
    }
  }, [activity]);

  if (!activity) {
    return null;
  }

  return (
    <div className="Toast" onClick={handleClick}>
      <div className="Toast__content">
        {activity === ToastActivity.LOADING && <Loader mode="gray" />}
        {label}
      </div>
    </div>
  )
}
