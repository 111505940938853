export interface UserModel {
  id: number;
  name: string;
  photos: string[];
  photosRich: PhotoModel[];
  bio: string;
  age: number;
  lookingFor: number;
  premium: boolean;
  distance: number;
  danger: boolean;
  sex: number;

  city: string;
  cityId: number;
  joinStep: number;
  bdate: string;
}

export enum VerificationStatusEnum {
  None = 'none',
  InVerification = 'in_verification',
  Declined = 'declined',
  Approved = 'approved',
}

export interface PhotoModel {
  id: number;
  url: string;
}

export interface LikeModel {
  id: number;
  fromId: number;
}

export interface MatchModel {
  id: number;
  fromId: number;
}

export interface DialogModel {
  peerId: number;
  lastId: number;
  unread: number;
  createdAt: number;
}

export interface MessageModel {
  id: number;
  fromId: number;
  text: string;
  createdAt: number;
  attachType: string;
  attachPayload: string;
  peerId: number;
}

export interface AdModel {
  id: number;
  text: string;
  url: string;
  photoUrl: string;
  buttonText: string;
  telegramLink: boolean;
}
