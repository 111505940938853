export const Cities = [
  {
    label: '- Выбрать город -',
    value: 0,
  },
  {
    label: 'Москва',
    value: 1,
  },
  {
    label: 'Санкт-Петербург',
    value: 2,
  },
  {
    label: 'Новосибирск',
    value: 3,
  },
  {
    label: 'Екатеринбург',
    value: 4,
  },
  {
    label: 'Казань',
    value: 5,
  },
  {
    label: 'Нижний Новгород',
    value: 6,
  },
  {
    label: 'Челябинск',
    value: 7,
  },
  {
    label: 'Самара',
    value: 8,
  },
  {
    label: 'Ростов-на-Дону',
    value: 9,
  },
  {
    label: 'Уфа',
    value: 10,
  },
  {
    label: 'Красноярск',
    value: 11,
  },
  {
    label: 'Пермь',
    value: 12,
  },
  {
    label: 'Воронеж',
    value: 13,
  },
  {
    label: 'Волгоград',
    value: 14,
  },
];
