import s from './CellButton.module.sass';

import { FC } from 'react';

import { CellButtonProps } from './CellButton.interface';

export const CellButtonGroup: FC<{ style?: any }> = (props) => {
  const { children, style = {} } = props;

  return (
    <div className={s.buttonGroup} style={style}>
      {children}
    </div>
  );
};

export const CellButton: FC<CellButtonProps> = (props) => {
  const { children, onClick, icon: Icon = null, indicator = null } = props;

  return (
    <div className={s.button} onClick={onClick}>
      <div className={s.buttonIn}>
        <div className={s.text}>{children}</div>
        {Icon && (
          <div className={s.icon}>
            <Icon />
          </div>
        )}
        {!!indicator && <div className={s.indicator}>{indicator}</div>}
      </div>
    </div>
  );
};
