import './style.sass';

import React, { FC, useEffect, lazy, Suspense, useState, useRef } from 'react';
import { classNames as cn, retry } from '$utils';

import('lottie-react');
const Lottie = lazy(() => retry(() => import('lottie-react')));

import { LottieIconProps } from './LottieIcon.interface';

export const LottieIcon: FC<LottieIconProps> = ({
  size = 120,
  name = '',
  height = 0,
  width = 0,
  autoplay = true,
  loop = false,
  className,
  onClick,
  onComplete,
  fallback,
  play = false,
}) => {
  const [animationData, setAnimationData] = useState<any | null>(null);
  useEffect(() => {
    if (name.indexOf('http') === 0) {
      fetch(name)
        .then((res) => res.json())
        .then((data) => setAnimationData(data));
    } else {
      import(`../../../assets/lottie/${name}.json`).then((e) => {
        setAnimationData(e);
      });
    }
  }, [name]);

  const ref = useRef<any>(null);

  useEffect(() => {
    if (play && ref.current) {
      ref.current.goToAndPlay(0);
    }
  }, [play, ref.current]);

  return (
    <div
      className={cn('LottieIcon', className)}
      onClick={onClick}
      style={{
        width: width || size,
        height: height || size,
      }}
    >
      {animationData ? (
        <Suspense fallback={<div />}>
          <Lottie
            lottieRef={ref}
            animationData={animationData}
            loop={loop}
            autoplay={autoplay}
            onComplete={onComplete}
          />
        </Suspense>
      ) : (
        fallback
      )}
    </div>
  );
};
