import s from './LikeItem.module.sass';

import { LikeItemProps } from '$core/Likes/LikeItem/LikeItem.interface';
import { FC, useCallback } from 'react';
import { useUser } from '$hooks';
import { PREMIUM, PROFILE_CARD } from '$shared/constants/pages';
import { useRouter } from 'react-router5';
import { classNames } from '$utils';
import { useSelector } from 'react-redux';
import { premiumRequiredSelector } from '$store/main';

export const LikeItem: FC<LikeItemProps> = (props) => {
  const user = useUser(props.userId);
  const router = useRouter();
  const premiumRequired = useSelector(premiumRequiredSelector);
  const isHidden = premiumRequired && !!props.canBlur;

  const handleClick = useCallback(() => {
    if (isHidden) {
      router.navigate(PREMIUM);
      return;
    }

    router.navigate(PROFILE_CARD, { id: props.userId });
  }, [props, isHidden]);

  return (
    <div
      className={classNames({
        [s.item]: true,
        [s.mini]: props.mini,
        [s.blur]: isHidden,
      })}
      onClick={props.onClick || handleClick}
    >
      <div className={s.cont}>
        <div
          className={s.photo}
          style={{
            backgroundImage: `url(${user?.photos[0]})`,
          }}
        />
        <div className={s.info}>
          {!isHidden && (
            <div className={s.name}>
              {user?.name}
              {user?.age > 0 ? `, ${user.age}` : ''}
            </div>
          )}
          {isHidden && <div className={s.fakeName} />}
        </div>
      </div>
    </div>
  );
};
