import './Edit.sass';

import React, { FC, useState, useCallback, useEffect } from 'react';
import {
  FormLayout,
  FormItem,
  Input,
  SegmentedControl,
  Spinner,
  Placeholder,
  Textarea,
  Select,
  PanelHeader,
  PanelHeaderBack,
} from '@vkontakte/vkui';
import { useDispatch } from 'react-redux';

import { PhotoModel } from '$store/models';
import { Api } from '$api';
import { canvasToBlob, showBannerAds } from '$utils';
import { toastActions } from '$store/toast';
import { useRoute, useRouter } from 'react-router5';
import { useTranslator } from '$hooks';
import { mainActions } from '$store/main';
import { batchActions } from '$store';
import { goBack } from '$navigation/router';
import { MainButton } from '$uikit/MainButton/MainButton';
import { PhotosEdit } from '$shared/components';
import { ScrollView } from '$uikit';
import { Cities } from '$shared/constants/cities';

export const Edit: FC = () => {
  const t = useTranslator();
  const { route } = useRoute();
  const router = useRouter();
  const dispatch = useDispatch();
  const isSetup = +(route.params?.setup ?? 0) === 1;
  const [loadingStatus, setLoadingStatus] = useState('loading');
  const [name, setName] = useState(isSetup ? window.initUserData?.name : '');
  const [bio, setBio] = useState('');
  const [sex, setSex] = useState(isSetup ? window.initUserData?.sex : '');
  const [bDate, setBDate] = useState(isSetup ? window.initUserData?.bDate : '');
  const [photos, setPhotos] = useState<PhotoModel[]>([]);
  const [city, setCity] = useState<number>(0);
  const isReg = +(route.params?.need_reg ?? 0) === 1;
  const triggerFind = route.params ? !!route.params.triggerFind : false;
  const [isSaving, setSaving] = useState(false);
  const [lookingFor, setLookingFor] = useState(0);

  const loadData = useCallback(() => {
    setLoadingStatus('loading');
    Api.get('/account/info')
      .then((res) => {
        const data: any = res;
        setName(data.name);
        //setSex(data.sex);
        setCity(data.cityId);
        setBDate(data.bDate);
        setPhotos(data.photos);
        setBio(data.bio);
        setLookingFor(data.lookingFor);

        setLoadingStatus('');
      })
      .catch((err) => {
        dispatch(toastActions.setToastFail(err.message));
        setTimeout(loadData, 3000);
      });
  }, []);

  useEffect(() => {
    if (!isSetup) {
      loadData();
    } else {
      setLoadingStatus('');
    }
    Telegram.WebApp.expand();
  }, []);

  const handleSave = () => {
    if (!sex) {
      dispatch(toastActions.setToastFail('Укажите пол'));
      return;
    }

    if (!name) {
      dispatch(toastActions.setToastFail('Укажите имя'));
      return;
    }

    if (!photos.length) {
      dispatch(toastActions.setToastFail('Добавьте фотографии'));
      return;
    }

    setSaving(true);
    Api.post<any, any>('/account/edit', {
      name: name.trim(),
      bio: bio.trim(),
      //sex: +sex,
      bDate,
      photos: photos.map((p) => p.id),
      lookingFor: +lookingFor,
      //triggerFind: route.params ? !!route.params.triggerFind : false,
    })
      .then((data) => {
        setSaving(false);
        dispatch(
          batchActions(
            mainActions.setUser(data.user),
            toastActions.hideToast(),
            mainActions.setNeedCreateProfileFalse(),
            mainActions.setCreated(),
          ),
        );
        goBack();

        showBannerAds();
      })
      .catch((err) => {
        setSaving(false);
        dispatch(toastActions.setToastFail(err.message));
      });
  };

  return (
    <>
      {window.isVK && (
        <PanelHeader before={<PanelHeaderBack onClick={() => goBack()} />}>
          {t('edit_title')}
        </PanelHeader>
      )}
      {loadingStatus === 'loading' ? (
        <Placeholder stretched>
          <Spinner />
        </Placeholder>
      ) : (
        <ScrollView>
          <FormLayout style={{ paddingBottom: 80 }}>
            <FormItem top={t('edit_photos')}>
              <PhotosEdit photos={photos} setPhotos={setPhotos} />
            </FormItem>
            <FormItem top={t('edit_name')}>
              <Input value={name} onChange={(e) => setName(e.target.value)} />
            </FormItem>
            {/*<FormItem top={t('edit_sex')}>*/}
            {/*  <SegmentedControl*/}
            {/*    size="l"*/}
            {/*    onChange={(value) => setSex(value ? +value : 0)}*/}
            {/*    defaultValue={sex}*/}
            {/*    options={[*/}
            {/*      {*/}
            {/*        label: t('edit_sex_female'),*/}
            {/*        value: 1,*/}
            {/*      },*/}
            {/*      {*/}
            {/*        label: t('edit_sex_male'),*/}
            {/*        value: 2,*/}
            {/*      },*/}
            {/*    ]}*/}
            {/*  />*/}
            {/*</FormItem>*/}
            <FormItem top={t('edit_sex_bdate')}>
              <Input
                type="date"
                value={bDate}
                onChange={(e) => setBDate(e.target.value)}
              />
            </FormItem>
            <FormItem top="Ваш город">
              <Select
                searchable
                defaultValue={city ? city : undefined}
                onChange={(newVal) => {
                  setCity(+newVal.target.value);
                }}
                options={Cities}
              />
            </FormItem>
            <FormItem top={t('edit_looking_for')} bottom={t('edit_looking_for_caption')}>
              <Select
                placeholder={t('edit_looking_for_placeholder')}
                value={lookingFor}
                onChange={(option) => setLookingFor(+option.target.value)}
                options={[
                  {
                    value: 1,
                    label: t('edit_looking_for_long_term'),
                  },
                  {
                    value: 2,
                    label: t('edit_looking_for_long_term_short'),
                  },
                  {
                    value: 3,
                    label: t('edit_looking_for_short_term_long'),
                  },
                  {
                    value: 4,
                    label: t('edit_looking_for_short_term'),
                  },
                  {
                    value: 5,
                    label: t('edit_looking_for_friends'),
                  },
                  {
                    value: 6,
                    label: t('edit_looking_for_not_sure'),
                  },
                ]}
              />
            </FormItem>
            <FormItem top={t('edit_bio')} bottom={t('edit_bio_tip')}>
              <Textarea
                onChange={(e) => setBio(e.target.value)}
                placeholder={t('edit_bio_placeholder')}
                value={bio}
              />
            </FormItem>
            <MainButton onClick={handleSave} text={t('save')} progress={isSaving} />
          </FormLayout>
        </ScrollView>
      )}
    </>
  );
};
