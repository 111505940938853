import s from './Home.module.sass';

import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mainActions, mainSelector } from '$store/main';
import { IcCards28, IcLike28, IcMessage28, IcSettings28 } from '$assets';
import { classNames, isIOS } from '$utils';
import { ACTIVITY, CARDS, LIKES, OTHER } from '$shared/constants/pages';
import { Cards } from '$core/Cards/Cards';
import { Likes } from '$core/Likes/Likes';
import { Activity } from '$core/Activity/Activity';
import { Other } from '$core/Other/Other';
import { ScrollView } from '$uikit';

export const Home: FC = () => {
  const { likesBadgeShown, activeTab } = useSelector(mainSelector);
  const dispatch = useDispatch();

  const tabs = useMemo(() => {
    return [
      {
        id: CARDS,
        icon: IcCards28,
      },
      {
        id: LIKES,
        icon: IcLike28,
        badge: !likesBadgeShown,
      },
      {
        id: ACTIVITY,
        icon: IcMessage28,
      },
      {
        id: OTHER,
        icon: IcSettings28,
      },
    ].map((tab) => {
      const Icon = tab.icon;
      return (
        <div
          className={classNames({
            [s.tab]: true,
            [s.tabActive]: activeTab === tab.id,
          })}
          key={tab.id}
          onClick={() => {
            dispatch(mainActions.setActiveTab(tab.id));
            document.body.scrollTop = 0;
          }}
        >
          <div className={s.tabIcon}>
            <Icon />
            {!!tab.badge && <div className={s.tabBadge} />}
          </div>
        </div>
      );
    });
  }, [activeTab, likesBadgeShown]);

  function renderTab() {
    switch (activeTab) {
      case CARDS:
        return <Cards />;
      case LIKES:
        return <Likes />;
      case ACTIVITY:
        return <Activity />;
      case OTHER:
        return <Other />;
      default:
        return <h1>Not Found</h1>;
    }
  }

  return (
    <div
      className={classNames({
        [s.wrap]: true,
        //[s.wrapTransparent]: activeTab === CARDS,
        //[s.fixPadding]: !window.isFromMenu && isIOS() && !window.isVK,
      })}
    >
      <div className={s.content}>
        <ScrollView>{renderTab()}</ScrollView>
      </div>
      {/*<div className={s.tabsHelper} />*/}
      <div className={s.tabs}>{tabs}</div>
    </div>
  );
};
