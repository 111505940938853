import './Settings.sass';

import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Group,
  Cell,
  Header,
  Switch,
  Placeholder,
  Spinner,
  PanelHeader,
  PanelHeaderBack,
} from '@vkontakte/vkui';
import { useTranslator } from '$hooks';
import { Api } from '$api';
import { toastActions } from '$store/toast';
import { goBack } from '$navigation/router';
import { MainButton, ScrollView } from '$uikit';

export const Settings: FC = () => {
  const t = useTranslator();
  const dispatch = useDispatch();
  const [loadingStatus, setLoadingStatus] = useState('loading');
  const [likeSound, setLikeSound] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const loadData = useCallback(() => {
    setLoadingStatus('loading');
    Api.get<any, any>('/account/preferences')
      .then((data) => {
        setLikeSound(data.likeSound);
        setLoadingStatus('');
      })
      .catch((err) => {
        dispatch(toastActions.setToastFail(err.message));
        setTimeout(loadData, 3000);
      });
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const handleSave = () => {
    setSaving(true);

    Api.post<any, any>('/account/preferences/save', {
      likeSound,
    })
      .then(() => {
        setSaving(false);
        goBack();
      })
      .catch((e) => {
        dispatch(toastActions.setToastFail(e.message));
        setSaving(false);
      });
  };

  function renderContent() {
    if (loadingStatus === 'loading') {
      return (
        <Placeholder stretched>
          <Spinner />
        </Placeholder>
      );
    }

    return (
      <>
        <Group header={<Header>{t('settings_notify')}</Header>}>
          <Cell
            after={
              <Switch
                checked={likeSound}
                onClick={(e) => {
                  setLikeSound(!likeSound);
                }}
              />
            }
            onClick={(e) => {
              // @ts-ignore
              if (e.target && e.target.closest('.Switch')) return;
              setLikeSound(!likeSound);
            }}
          >
            {t('settings_notify_new_like')}
          </Cell>
        </Group>
        <MainButton onClick={handleSave} text={t('save')} progress={isSaving} />
      </>
    );
  }

  return (
    <>
      {window.isVK && (
        <PanelHeader before={<PanelHeaderBack onClick={() => goBack()} />}>
          {t('settings_title')}
        </PanelHeader>
      )}
      <ScrollView>{renderContent()}</ScrollView>
    </>
  );
};
