import { FC } from 'react';
import { Button, PanelHeader, PanelHeaderBack, Placeholder } from '@vkontakte/vkui';
import { goBack } from '$navigation/router';
import { LottieIcon } from '$shared/components';
import { useRouter } from 'react-router5';
import { PREMIUM } from '$shared/constants/pages';
import { useTranslator } from '$hooks';

export const LikesLimit: FC = () => {
  const router = useRouter();
  const t = useTranslator();

  const handlePremium = () => {
    router.navigate(PREMIUM);
  };

  return (
    <>
      {window.isVK && (
        <PanelHeader before={<PanelHeaderBack onClick={() => goBack()} />} />
      )}
      <Placeholder
        stretched
        icon={<LottieIcon name="stop" />}
        header={t('likes_limit_title')}
        action={
          <Button size="l" onClick={handlePremium}>
            {t('likes_limit_btn')}
          </Button>
        }
      >
        {t('likes_limit_caption')}
      </Placeholder>
    </>
  );
};
